import React from "react";
import "./navbar.css";
import { Nav } from "react-bootstrap";
import { MdOutlineDashboard } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { FaFileInvoice } from "react-icons/fa6";
import { GrTemplate } from "react-icons/gr";
import { HiOutlineUsers } from "react-icons/hi";
import { BsTicketPerforated } from "react-icons/bs";
import { ImTicket } from "react-icons/im";
import { BsCashStack } from "react-icons/bs";
import { BsReceiptCutoff } from "react-icons/bs";
import { MdOutlinePermMedia } from "react-icons/md";
import { BsPersonRolodex } from "react-icons/bs";
import { CiReceipt } from "react-icons/ci";
import { GiBlackBook } from "react-icons/gi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { IoTicketOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function LeftNavBar(props) {
  const { userInfo } = useSelector((state) => state.userInfo);
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const userName = user && `${user.firstname} ${user?.lastname}`;
  const role = user && `${user?.role?.role}`;
  const first = user && `${user.firstname}`;
  const last = user && `${user?.lastname}`;
  const checkRole = userInfo?.user_data?.role;
  const location = useLocation();

  const menuItems = [
    {
      title: "Dashboard",
      icon: <MdOutlineDashboard size={18} />,
      // <FcPieChart />
      path: "/",
      subMenu: [],
      showInMenu: true,
    },
    checkRole?.invoice?.length > 0 && {
      title: "Invoice",
      icon: <FaFileInvoice size={18} />,
      path: "",
      showInMenu: true,
      subMenu: [
        {
          title: "All Invoice",
          icon: <LiaFileInvoiceSolid size={18} />,
          path: "/invoices",
          subMenu: [],
          showInMenu: true,
        },
        (checkRole?.invoice?.includes("create") ||
          checkRole?.invoice?.includes("Create")) && {
          title: "Create New Invoice",
          icon: <TbFileInvoice size={18} />,
          path: "/create-new-invoice",
          subMenu: [],
          showInMenu: true,
        },
      ],
    },
    checkRole?.vouchers?.length > 0 && {
      title: "Voucher",
      icon: <BsTicketPerforated size={18} />,
      path: "",
      showInMenu: true,
      subMenu: [
        {
          title: "All Voucher",
          icon: <ImTicket size={18} />,
          path: "/vouchers",
          subMenu: [],
          showInMenu: true,
        },
        (checkRole?.vouchers?.includes("create") ||
          checkRole?.vouchers?.includes("Create")) && {
          title: "Create New Voucher",
          icon: <CiReceipt size={18} />,
          path: "/create-new-voucher",
          subMenu: [],
          showInMenu: true,
        },
      ],
    },
    checkRole?.roles?.length > 0 && {
      title: "Roles",
      icon: <BsPersonRolodex size={18} />,
      path: "/user-roles",
      subMenu: [],
      showInMenu: true,
    },
    // (checkRole?.template?.includes("access") ||
    //   checkRole?.template?.includes("Access")) && {
    //   title: "Templates",
    //   icon: <GrTemplate size={18} />,
    //   path: "/invoiceTemplate",
    //   subMenu: [],
    //   showInMenu: true,
    // },
    {
      title: "Template",
      icon: <GrTemplate size={18} />,
      path: "",
      showInMenu: true,
      subMenu: [
        // (checkRole?.template?.includes("access") ||
        //   checkRole?.template?.includes("Access")) && 
          {
          title: "Invoice Template",
          icon: <LiaFileInvoiceSolid size={18} />,
          path: "/invoiceTemplate",
          subMenu: [],
          showInMenu: true,
        },
        // (checkRole?.template?.includes("access") ||
        //   checkRole?.template?.includes("Access")) && 
          {
          title: "Voucher Template",
          icon: <IoTicketOutline size={18} />,
          path: "/voucherTemplate",
          subMenu: [],
          showInMenu: true,
        },
      ],
    },
    checkRole?.users?.length > 0 && {
      title: "Users",
      icon: <HiOutlineUsers size={18} />,
      path: "/users",
      subMenu: [],
      showInMenu: true,
    },
    {
      title: "Media",
      icon: <MdOutlinePermMedia size={18} />,
      path: "/media",
      subMenu: [],
      showInMenu: true,
    },
    checkRole?.cash_book?.length > 0 && {
      title: "Cashbook",
      icon: <BsCashStack size={18} />,
      path: "/cashbook",
      subMenu: [],
      showInMenu: true,
    },
    // checkRole?.khata_book?.length > 0 && {
    //   title: "Khatabook",
    //   icon: <GiBlackBook size={18} />,
    //   path: "/khatabook",
    //   subMenu: [],
    //   showInMenu: true,
    // },
  ];
  return (
    <Nav className="leftNavigationDrawer bg-white border-end overflow-scroll d-flex align-content-start h-100 overflow-y-auto">
      <div className="container-fluid h-100 align-content-start p-0">
        <div className="d-flex flex-row align-items-center rounded ms-2 me-2 mb-3" style={{backgroundColor: "#F9FBFD", height:"70px"}}>
          <div className="d-flex align-items-center justify-content-center ms-3 me-3" style={{height:'40px', width:'40px', borderRadius:'100%', backgroundColor:'orange',textAlign:'center',padding:'4px'}}>
            <span className="fs-5 fw-semibold text-white">{first[0]}{last[0]}</span>
          </div>
          <div className="">
            <div className="fw-semibold">{userName}</div>
            <div className="text-capitalize" style={{fontSize:"12px"}}>{role}</div>
          </div>
        </div>
        <div className="navbar-collapse" id="sidebarCollapse">
          <ul className="navbar-nav">
            {menuItems
              ?.filter((row) => typeof row === "object")
              ?.map((item, index) => (
                <li
                  key={index}
                  className={`nav-item ${
                    location.pathname === item.path
                      ? "activnavbar"
                      : "hovernavbar"
                  }`}
                >
                  {item.subMenu.length ? (
                    <>
                      <div
                        key={`menuItems_${item.id}`}
                        href={`#dropdown-${item.title}`}
                        className="nav-link mx-2 d-flex align-items-center"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls={`dropdown-${item.title}`}
                      >
                        {item.icon}
                        <div className="ps-2 ">{item.title}</div>
                      </div>
                      <div
                        className="collapse"
                        key={`menuItemsDropdown_${item.id}`}
                        id={`dropdown-${item.title}`}
                      >
                        <ul className="nav nav-sm flex-column">
                          {item.subMenu
                            ?.filter((key) => typeof key === "object")
                            ?.map((subMenuItem) => (
                              <li
                                className={`nav-item ${
                                  location.pathname === subMenuItem.path
                                    ? "activnavbar"
                                    : "hovernavbar"
                                }`}
                                key={subMenuItem.title}
                              >
                                <Link
                                  to={subMenuItem.path}
                                  className="nav-link d-flex align-items-center"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="sidebar-tasks"
                                >
                                  {subMenuItem.icon}
                                  <div className="ps-2">
                                    {subMenuItem.title} 
                                  </div>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <Link
                      to={item.path}
                      className="nav-link mx-2 d-flex align-items-center"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebar-tasks"
                    >
                      {item.icon}
                      <div className="ps-2">{item.title}</div>
                    </Link>
                  )}
                </li>
              ))}
          </ul>
        </div>
        <div className="h-25"></div>
      </div>
    </Nav>
  );
}
