import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  InputGroup
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "./Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function Templates1(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const { paymentDetails } = useSelector((state) => state.paymentDetails);

  const formatDate = userInfo?.account_preferences?.date_formate;
  const filterPaymentDetails = paymentDetails?.filter((row) => row?.isPrimary);
  const {
    requestType,
    isCreateInvoice,
    applyGST,
    applyDIS,
    toWords,
    addFields,
    removeFields,
    setApplyGST,
    setApplyDIS,
    disableSubmit,
    formik,
    showTemplate,
    handlePaidToChange,
    paymentDetailsEditable,
    setPaymentDetailsEditable,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    setIsDataLoaded(true);
  }, []);
  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/invoices");
    }
  }, [state?.download, isDataLoaded, navigate]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  function capitalizeWords(sentence) {
    // Split the sentence into words
    const words = sentence.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a sentence
    return capitalizedWords.join(" ");
  }

  return (
    <div className="main222">
      <Card style={{ width: 800 }}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref}>
              <div className="line my-3"></div>
              <div className="mx-4">
                <Row>
                  <Col className="px-0">
                    <div>
                    <div style={{marginBottom: "30px"}}>
                    <Form.Text className="text1">Invoice</Form.Text>
                      {!isCreateInvoice && (
                      <>
                        <Form.Text
                          className="text11 text-start"
                          style={{ lineHeight: "1.2",marginTop:"-10px" }}
                        >
                          {values?.invoice_no}
                        </Form.Text>
                      </>
                    )}
                    </div>
                    
                    <div className={`${requestType === "edit" || isCreateInvoice ? "" : "d-flex align-items-center" } m-0`}
                      style={{paddingBottom:requestType === "edit" || isCreateInvoice?'':'0'}}
                      >
                    <Form.Text className="text111 text-start mt-0  me-2">
                      DATE:
                    </Form.Text>
                    {requestType === "edit" || isCreateInvoice ? (
                      <div className="text-end">
                        <DatePicker
                          wrapperClassName="datewrapper"
                          customInput={
                            <Form.Control
                              aria-describedby="basic-addon1"
                              className="my-1 text-end w-100"
                            />
                          }
                          required
                          maxDate={new Date()}
                          name="date"
                          id="date"
                          dateFormat={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            values?.date ? new Date(values?.date) : null // Updated to handle null date
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "date",
                              e ? moment(e).format("YYYY-MM-DD") : "" // Updated to handle empty date
                            );
                          }}
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />

                        <Form.Control.Feedback
                          className="d-block mb-1"
                          type="invalid"
                        >
                          {errors.date}
                        </Form.Control.Feedback>
                      </div>
                    ) : (
                      <Form.Text className="text11 my-0">
                        {moment(values?.date).format(
                          `${
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                          }`
                        )}
                      </Form.Text>
                    )}
                    </div>
                      <Form.Text className="text111 text-start d-block mt-0">
                        BILL TO:
                      </Form.Text>
                      {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          autoComplete="off"
                          requestType="bills"
                          searchingFor="paid_to"
                          callbackFieldValue={handlePaidToChange}
                          fieldValue={values.paid_to}
                          className="mt-0 mb-2 w-100"
                          name="paid_to"
                          id="paid_to"
                          placeholder="Enter Company Name"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 text-start mt-0"
                          style={{ width: "100%" }}
                        >
                          {values?.paid_to}
                        </Form.Text>
                      )}
                      {touched.paid_to && errors.paid_to && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors.paid_to}
                        </Form.Control.Feedback>
                      )}
                      {/* <Form.Text className="text111 text-start my-2 d-block ">
                        ADDRESS:
                      </Form.Text> */}
                      {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          requestType="bills"
                          searchingFor="address"
                          name="address"
                          callbackFieldValue={(e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          fieldValue={values.address}
                          as="textarea"
                          rows={2}
                          required
                          className="mt-0 w-100"
                          placeholder="Enter Address"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 text-start mt-0"
                          style={{ width: "100%" }}
                        >
                          {values?.address}
                        </Form.Text>
                      )}
                      {touched.address && errors.address && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.address}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col className="px-0 ">
                    <div className="text11 mb-2 " style={{marginTop:"20px"}}>
                      {" "}
                      {userInfo?.account_preferences?.attached_logo ? (
                        <img
                          alt="temp"
                          src={`
                              ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                            `}
                          style={{
                            height: "80px",
                          }}
                        />
                      ) : (
                        "{Logo}"
                      )}
                    </div>
                   <div style={{marginTop:"42px"}}>
                    <Form.Text
                      className="text111 my-1"
                      style={{ lineHeight: "1.2" }}
                    >
                      FROM:
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.address
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                        : "{Conpany address, City, State}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.pincode
                        ? userInfo?.account_preferences?.pincode
                        : "{Pincode}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                        : ""}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.email_address
                        ? userInfo?.account_preferences?.email_address
                        : "{Email address}"}
                    </Form.Text>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mx-4">
                <Table striped hover className="mt-1">
                  <thead>
                    <tr>
                      <th
                        className="text9 text-start"
                        style={{ width: "135px" }}
                      >
                        Date
                      </th>
                      <th className="text9 text-start">Details</th>
                      <th
                        className="text9 text-start"
                        style={{ width: "135px" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9 text-start"
                        style={{ width: "120px" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="text9 text-start"
                        style={{
                          width: "140px",
                        }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateInvoice &&
                          values?.voucher_descriptions?.length > 1)) && (
                        <th className="text9 text-right "></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.voucher_descriptions?.map((row, index) => {
                      total +=
                        row.quantity || row.item_price
                          ? parseFloat(row.quantity * row.item_price)
                          : 0;
                      subTotal = total;
                      Quantity += row.quantity ? parseFloat(row.quantity) : 0;

                      appliedgst = (subTotal * (values?.has_gst || 0)) / 100;
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;

                      discountVal = (
                        parseFloat(subTotal) +
                        parseFloat(applyGST ? appliedgst : 0) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);
                      return (
                        <tr key={index} className="temp1row">
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <DatePicker
                                wrapperClassName="datewrapper"
                                customInput={
                                  <Form.Control
                                    aria-describedby="basic-addon1"
                                    className="w-100"
                                  />
                                }
                                required
                                maxDate={new Date()}
                                name="date"
                                id="date"
                                // change
                                dateFormat={
                                  formatDate === null ||
                                  formatDate === undefined
                                    ? "dd MMM, yyyy"
                                    : formatDate
                                        ?.replace(/D/g, "d")
                                        ?.replace(/Y/g, "y")
                                }
                                selected={
                                  values.voucher_descriptions[index]?.item_date
                                    ? new Date(
                                        values.voucher_descriptions[
                                          index
                                        ].item_date
                                      )
                                    : null
                                }
                                onChange={(date) => {
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_date`,
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  );
                                }}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_date`
                                )}
                              >
                                {moment(
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_date`
                                  ).value
                                ).format(
                                  `${
                                    formatDate === null ||
                                    formatDate === undefined
                                      ? "DD MMM, YYYY"
                                      : formatDate
                                  }`
                                )}
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_date &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.item_date && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_date}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <AutoComplete
                                requestType="billdescriptions"
                                searchingFor="item_description"
                                name={`voucher_descriptions.${index}.item_description`}
                                callbackFieldValue={(e) =>
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_description`,
                                    e.target.value
                                  )
                                }
                                fieldValue={row.item_description}
                                as="textarea"
                                rows={1}
                                required
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]
                                ?.item_description &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_price`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_price &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_price && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={4}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.quantity &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.quantity && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                onKeyPress={numberonly}
                                value={(row.item_price * row.quantity).toFixed(
                                  2
                                )}
                                rows={1}
                                readOnly
                                name="Payments"
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                              >
                                {(row.item_price * row.quantity).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>
                          {(requestType === "edit" ||
                            (isCreateInvoice &&
                              values?.voucher_descriptions?.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot >
                      <tr>
                        <td
                          colSpan={4}
                          style={{ borderWidth: "", padding: "1px 4px 4px 150px" }}
                        > <Row className="d-flex align-items-center  mb-1">
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Sub Total
                              </Form.Text>
                            </Col>
                          </Row>
                          {!(
                            requestType === "view" &&
                            !isCreateInvoice &&
                            !values?.has_discounts
                          ) && (
                            <Row  style={{display:'flex', alignItem:'baseline'}}>
                              <Col className="ps-0">
                                <Form.Group controlId="applyDIS" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                    checked={applyDIS}
                                    onChange={() => setApplyDIS(!applyDIS)}
                                    type="checkbox"
                                    label="Apply Discount"
                                  />
                                </Form.Group>
                              </Col>
                              {applyDIS && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div className="d-flex flex-row sizewt align-items-center pb-1" >
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      maxLength={3}
                                      type="number"
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      value={values?.has_discounts}
                                      // onChange={(e) => {
                                      //   setFieldValue(
                                      //     "has_discounts",
                                      //     e.target.value
                                      //   );
                                      // }}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_discounts",
                                          nonNegativeValue
                                        );
                                      }}
                                      name="disctval"
                                      style={{
                                        border: requestType === "edit" || isCreateInvoice ? "1px solid #666276" : "none",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "60px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign:'end',                             
                                        borderRadius:'6px'

                                      }}
                                    />
                                  </div>
                                  <div className="d-flex align-items-center flex-row sizewt pb-1" style={{marginLeft:'5px'}}>
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;=&nbsp;
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                          {applyDIS && (
                            <Row className="d-flex align-items-center mb-1" style={{marginTop:"-3px"}}>
                              <Col className="px-0">
                                <Form.Text className="text111 text-start">Net Total</Form.Text>
                              </Col>
                            </Row>
                          )}
                          {!(
                            requestType === "view" &&
                            !isCreateInvoice &&
                            !values?.has_gst
                          ) && (
                            <Row  style={{display:'flex', alignItem:'baseline'}}>
                              <Col className="ps-0">
                                <Form.Group controlId="ApplyGST" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    checked={applyGST}
                                    onChange={() => setApplyGST(!applyGST)}
                                    type="checkbox"
                                    label="Apply GST"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              {applyGST && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      name="has_gst"
                                      maxLength={5}
                                      type="number"
                                      value={values?.has_gst}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      // onChange={(e) => {
                                      //   setFieldValue("has_gst", e.target.value);
                                      // }}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_gst",
                                          nonNegativeValue
                                        );
                                      }}
                                      style={{
                                        border: requestType === "edit" || isCreateInvoice ? "1px solid #666276" : "none",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "60px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign:'end',
                                        borderRadius:'6px'
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex align-items-center  flex-row sizewt pb-1" style={{marginLeft:'5px'}}>
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;=&nbsp;
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                         
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1 ? 2 : requestType === "edit"?2:1
                          }
                          style={{ borderWidth: "", verticalAlign:'top', paddingTop:'6px' }}
                        >
                          <Row className="mb-1">
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${total.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                          <Row style={{marginTop:applyGST?'4px':'27px'}}>
                            <Col className="text-start p-0">
                              {applyDIS ? (
                                <Form.Text className="sizewt">
                                  {`₹${applieddiscount.toFixed(2)}`}
                                </Form.Text>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <Col className="text-start p-0" style={{marginTop:applyDIS?'2px':'0'}}>
                            {applyDIS && (
                              <Form.Text className="sizewt">
                              {`₹${netTotal}`}
                              </Form.Text>
                            )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="text-start p-0" style={{marginTop:"1px"}}>
                              {applyGST ? (
                                <Form.Text className="sizewt">
                                  {`₹${appliedgst.toFixed(2)}`}
                                </Form.Text>
                              ) : null}
                            </Col>
                          </Row>{" "}

                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{ borderWidth: "", paddingLeft: "145px",backgroundColor: "#5e58d1" }}
                        >
                          <Row className="d-flex align-items-center">
                            <Col className="px-0">
                              <Form.Text
                                className="total1"
                              >
                                Total
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1 ? 2 : requestType === "edit"?2:1
                          }
                          style={{ backgroundColor: "#5e58d1" }}
                        >
                          <Row className="m-0 p-0">
                            <Col className="text-start p-0">
                              <Form.Text
                                className="total1 "
                                style={{ textAlign: "start" }}
                              >
                                {`₹${parseFloat(discountVal).toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tfoot>
                </Table>
                <Row className="mt-1">
                  <Col sm={6} className="px-0">
                    <div style={{ maxWidth: "300px" }}>
                      <div className="d-flex  justify-content-between">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Payment Details :
                        </Form.Text>
                        {requestType === "edit" ||
                          (isCreateInvoice && (
                            <Button
                              size="sm"
                              onClick={() => setPaymentDetailsEditable(true)}
                              variant="light"
                              className="text-primary"
                            >
                              Edit
                            </Button>
                          ))}
                      </div>
                      {!(requestType === "view" && !isCreateInvoice) &&

                      filterPaymentDetails
                        ?.sort((a, b) => b?.mode - a?.mode)
                        ?.map((payment) => (
                          <Form.Check
                            inline
                            label={capitalizeWords(payment?.mode)}
                            checked={values.payment_Details
                              ?.map((row) => row?.mode)
                              ?.includes(payment?.mode)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("payment_Details", [
                                  ...values.payment_Details,
                                  payment,
                                ]);
                              } else {
                                setFieldValue(
                                  "payment_Details",
                                  values.payment_Details?.filter(
                                    (row) => row?.mode !== payment?.mode
                                  )
                                );
                              }
                            }}
                            type="checkbox"
                            disabled={
                              !(requestType === "edit" || isCreateInvoice)
                            }
                          />
                        ))}{" "}
                      {touched.payment_Details && errors.payment_Details && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors?.payment_Details}
                        </Form.Control.Feedback>
                      )}
                      {values?.payment_Details?.length > 0
                        ? values?.payment_Details?.map((payment, index) => (
                            <Row className="d-flex" key={index}
                              style={{
                               borderBottom: index < values.payment_Details.length - 1 ? "1px solid #efecf3" : "none",
                               paddingBottom: index < values.payment_Details.length - 1 ? "7px" : "none"
                              }}
                              >
                              {/* <Col sm={12} xs={12} className="p-0">
                                <Form.Text
                                  className="text111 text-start p-0 my-1"
                                  style={{
                                    width: "100%",
                                    borderBottom: "1px solid #efecf3",
                                  }}
                                >
                                  {(payment?.mode === "bank" ||
                                    payment?.mode === "other") &&
                                    capitalizeWords(payment?.mode)}
                                </Form.Text>
                              </Col> */}
                              {payment?.mode === "bank" && (
                                <>
                                  {/** Add Bank Name */}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Bank Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.bankName}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.accountName}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Number:</b>{" "}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.accountNumber}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>IFSC code:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.ifscCode}
                                    </Form.Text>
                                  </Col>
                                  {payment?.swiftCode !== "" && (
                                    <>
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          <b>Swift Code:</b>
                                        </Form.Text>
                                      </Col>{" "}
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          {payment?.swiftCode}
                                        </Form.Text>
                                      </Col>
                                    </>
                                  )}
                                </>
                              )}
                              {(payment?.mode === "UPI" ||
                                payment?.mode === "Paypal") && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>{payment?.mode} Id:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.payment_id}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                              {payment?.mode === "other" && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Title:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.title}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Description:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.discription}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                            </Row>
                          ))
                        : null}{" "}
                    </div>
                  </Col>

                  <Col sm={6} className="px-0">
                  <InputGroup className="">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Other Details:
                        </Form.Text>
                        <AutoComplete
                          wrapperClassName={
                            isCreateInvoice
                              ? "w-100"
                              : "w-100"
                          }
                          requestType="bills"
                          searchingFor="otherInfo"
                          callbackFieldValue={(e) =>
                            setFieldValue("otherInfo", e.target.value)
                          }
                          fieldValue={values.otherInfo}
                          as="textarea"
                          rows={3}
                          className="w-100 rounded"
                          name="otherInfo"
                          id="otherInfo"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Other Details"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      </InputGroup>
                    {/* <Row className="d-flex">
                      <Col sm={5} xs={5} className="p-0">
                        {" "}
                        <Form.Text className="text111 text-start">
                          Sub Total
                        </Form.Text>
                      </Col>
                      <Col xs={7} sm={7} className={` ${" text-end "}`}>
                        <Form.Text className="sizewt">
                          {`₹${total.toFixed(2)}`}
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="my-1 mb-2">
                      <Col sm={5} xs={5} className="p-0">
                        <Form.Text className="text111 text-start">
                          Amount in Words:
                        </Form.Text>
                      </Col>
                      <Col sm={7} xs={7}>
                        <Form.Text className=" sizewt text-end d-block">
                          {toWords.convert(parseInt(Math.round(total)))}
                        </Form.Text>
                      </Col>
                    </Row>

                    {!(
                      requestType === "view" &&
                      !isCreateInvoice &&
                      !values?.has_gst
                    ) && (
                      <Row className="">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="ApplyGST" md="auto">
                            <Form.Check
                              className=" text-start sizewt"
                              checked={applyGST}
                              onChange={() => setApplyGST(!applyGST)}
                              type="checkbox"
                              label="Apply GST"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                            />
                          </Form.Group>
                        </Col>
                        {applyGST && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row pe-0  px-0 align-items-center"
                            >
                              <div className="d-flex flex-row sizewt align-items-center">
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  name="has_gst"
                                  maxLength={5}
                                  type="number"
                                  value={values?.has_gst}
                                  readOnly={
                                    !(requestType === "edit" || isCreateInvoice)
                                  }
                                  // onChange={(e) => {
                                  //   setFieldValue("has_gst", e.target.value);
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue("has_gst", nonNegativeValue);
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center flex-row sizewt ">
                                {" "}
                                <BsPercent className="" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {!(
                      requestType === "view" &&
                      !isCreateInvoice &&
                      !values?.has_discounts
                    ) && (
                      <Row className="">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="applyDIS" md="auto">
                            <Form.Check
                              className="sizewt text-start"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                              checked={applyDIS}
                              onChange={() => setApplyDIS(!applyDIS)}
                              type="checkbox"
                              label="Apply Discount"
                            />
                          </Form.Group>
                        </Col>
                        {applyDIS && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row pe-0  px-0 align-items-center "
                            >
                              <div className="d-flex flex-row sizewt align-items-center">
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  maxLength={3}
                                  type="number"
                                  readOnly={
                                    !(requestType === "edit" || isCreateInvoice)
                                  }
                                  value={values?.has_discounts}
                                  // onChange={(e) => {
                                  //   setFieldValue(
                                  //     "has_discounts",
                                  //     e.target.value
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue(
                                      "has_discounts",
                                      nonNegativeValue
                                    );
                                  }}
                                  name="disctval"
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center sizewt">
                                {" "}
                                <BsPercent className="" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    <Row className="d-flex mt-4">
                      <Col
                        className="d-flex justify-content-between d-flex align-items-center"
                        sm={12}
                        style={{
                          backgroundColor: "#5e58d1",
                          padding: `16px 8px 16px 8px`,
                        }}
                      >
                        <Form.Text className="total1 ms-1">Total</Form.Text>
                        <Form.Text className="total1 ">
                          {`₹${parseFloat(discountVal).toFixed(2)}`}
                        </Form.Text>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
              </div>

              {/* <Row className="mt-3">
                <Col
                  className="ps-4 pb-1"
                  style={{ backgroundColor: "#e0dff2" }}
                  sm={6}
                >
                  {!(
                    requestType === "view" &&
                    !isCreateInvoice &&
                    !values?.note
                  ) && (
                    <div style={{ width: "97%" }}>
                      <Form.Text className="text111 text-start my-1 ">
                        Notes:
                      </Form.Text>
                      {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          requestType="bills"
                          searchingFor="note"
                          callbackFieldValue={(e) =>
                            setFieldValue("note", e.target.value)
                          }
                          fieldValue={values.note}
                          className="my-2 w-100"
                          name="note"
                          id="note"
                          placeholder="Write something here"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 border-0  text-start"
                          style={{ width: "100%" }}
                        >
                          {values && values?.note}
                        </Form.Text>
                      )}
                    </div>
                  )}
                </Col>

                <Col
                  className="d-flex justify-content-between d-flex align-items-center"
                  sm={6}
                  style={{
                    backgroundColor: "#5e58d1",
                    padding: `16px 36px 16px 16px`,
                  }}
                >
                  <Form.Text className="total1 ms-2">Total</Form.Text>
                  <Form.Text className="total1 ">
                    {`₹${parseFloat(discountVal).toFixed(2)}`}
                  </Form.Text>
                </Col> 
              </Row>*/}
            </div>
          </div>

          {!showTemplate && (
            <Row className="position-sticky bottom-0 p-2 mt-5 mb-3">
              {requestType === "edit" || isCreateInvoice ? (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to change template
                        </Tooltip>
                      }
                    >
                      <Button
                        // variant="secondary"
                        style={{ width: "100%", backgroundColor:"#FD5631" }}
                        onClick={() => {
                          if (requestType === "edit") {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/invoiceTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateInvoice,
                              },
                            });
                          }
                        }}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">Click to add row</Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={addFields}
                      >
                        Add Row
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all invoices
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: "100%" }}
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print template pdf
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        className="primary w-100"
                        style={{
                          width: "100%",
                          // backgroundColor: "#5e58d1",
                          marginLeft: "3px",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download pdf
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `${values?.invoice_no}.pdf`,
                          });
                        }}
                        variant="secondary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Form>
      </Card>
    </div>
  );
}
