import React, { useRef, useEffect, useState } from "react";
import { Card, Form, Row, Table, Col, Button } from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "../Invoice/Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function VoucherTemplate3(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const formatDate = userInfo?.account_preferences?.date_formate;

  const {
    requestType,
    isCreateVoucher,
    toWords,
    addFields,
    removeFields,
    disableSubmit,
    formik,
    applyGST,
    applyDIS,
    setApplyGST,
    setApplyDIS,
    handlePaidToChange,
    fromCashBook = false,
    handleUnlinkVoucher,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    setIsDataLoaded(true);
  }, []);
  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/vouchers");
    }
  }, [state?.download, isDataLoaded, navigate]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  return (
    <div className="main222">
      <Card style={{ width: "800px" }}>
        <Form noValidate onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref}>
              <Row className="d-flex justify-content-between  p-4">
                <Col className="d-flex justify-content-start ps-0" sm={6}>
                  {" "}
                  {userInfo?.account_preferences?.attached_logo ? (
                    <img
                      alt="temp"
                      src={`
                        ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                          `}
                      style={{
                        height: "60px",
                      }}
                    />
                  ) : (
                    "{Logo}"
                  )}
                  {/* <div className=" p-0 m-0 ps-1">
                    {" "}
                    <Form.Text
                      className="d-block m-0"
                      style={{ fontSize: "19px", color: "#f79234" }}
                    >
                      {" "}
                      IPH{" "}
                    </Form.Text>
                    <Form.Text
                      className="d-block m-0"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#006cb5",
                      }}
                    >
                      {" "}
                      TECHNOLOGIES{" "}
                    </Form.Text>
                    <Form.Text
                      className="d-block m-0"
                      style={{ fontSize: "19px", color: "#f79234" }}
                    >
                      {" "}
                      PVT. LTD.
                    </Form.Text>
                  </div> */}
                </Col>
                <Col>
                  <div>
                    <Form.Text
                      className="d-block mt-0 text-end "
                      style={{ fontSize: "35px" }}
                    >
                      VOUCHER
                    </Form.Text>
                    {!isCreateVoucher && (
                      <>
                        <Form.Text
                          className="text11 p-0"
                          style={{ lineHeight: "1.2", marginTop: "-5px" }}
                        >
                          {values?.voucher_no}
                        </Form.Text>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between px-4">
                <Col sm={6} className="ps-0">
                  {" "}
                  <Form.Text className="text111 text-start">PAID TO:</Form.Text>
                  {requestType === "edit" || isCreateVoucher ? (
                    <AutoComplete
                      requestType="bills"
                      searchingFor="paid_to"
                      callbackFieldValue={handlePaidToChange}
                      fieldValue={values.paid_to}
                      className="w-100 mt-1 "
                      name="paid_to"
                      id="paid_to"
                      placeholder="Enter Company Name"
                      readOnly={!(requestType === "edit" || isCreateVoucher)}
                    />
                  ) : (
                    <Form.Text
                      className="text4   text-start"
                      style={{ width: "50%" }}
                    >
                      {values?.paid_to}
                    </Form.Text>
                  )}
                  {touched.paid_to && errors.paid_to && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mt-0 "
                      style={{ marginLeft: "13px" }}
                    >
                      {errors.paid_to}
                    </Form.Control.Feedback>
                  )}
                  {/* <Form.Text className="text111 text-start mt-2">
                    ADDRESS:
                  </Form.Text> */}
                  {requestType === "edit" || isCreateVoucher ? (
                    <AutoComplete
                      requestType="bills"
                      searchingFor="address"
                      name="address"
                      // callbackFieldValue={(e) =>
                      //   setFieldValue("address", e.target.value)
                      // }
                      callbackFieldValue={(e) => {
                        const inputValue = e.target.value;

                        if (inputValue.length > 255) {
                          alert("Address length is too long");
                          return;
                        }

                        setFieldValue("address", inputValue);
                      }}
                      fieldValue={values.address}
                      as="textarea"
                      rows={1}
                      required
                      className="mt-1 w-100"
                      placeholder="Enter Address"
                      readOnly={!(requestType === "edit" || isCreateVoucher)}
                    />
                  ) : (
                    <Form.Text
                      className="text4  text-start "
                      style={{ width: "100%" }}
                    >
                      {values?.address}
                    </Form.Text>
                  )}
                  {touched.address && errors.address && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block my-0 "
                      style={{ marginLeft: "13px" }}
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  )}
                  <div className="d-flex mt-1">
                    <Form.Text className="text111 mt-0 me-2">DATE:</Form.Text>
                    {requestType === "edit" || isCreateVoucher ? (
                      <div className="">
                        <DatePicker
                          wrapperClassName="datewrapper"
                          customInput={
                            <Form.Control
                              aria-describedby="basic-addon1"
                              className="text-end w-100"
                            />
                          }
                          required
                          maxDate={new Date()}
                          name="date"
                          id="date"
                          // change
                          dateFormat={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            values?.date ? new Date(values?.date) : null
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "date",
                              e ? moment(e).format("YYYY-MM-DD") : ""
                            );
                          }}
                          readOnly={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        />

                        <Form.Control.Feedback
                          className="d-block mb-1"
                          type="invalid"
                        >
                          {errors.date}
                        </Form.Control.Feedback>
                      </div>
                    ) : (
                      <Form.Text className="text11 my-0">
                        {moment(values?.date).format(
                          `${
                            formatDate === null || formatDate === undefined
                              ? "DD MMM, YYYY"
                              : formatDate
                          }`
                        )}
                      </Form.Text>
                    )}
                  </div>
                </Col>
                <Col sm={6} className="px-0 ">
                  <Row className="text-center mt-2">
                    {" "}
                    <Form.Text className="text111 mt-0">FROM:</Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.address ||
                      userInfo?.account_preferences?.state
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                        : "{Company address - pincode}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.pincode
                        ? userInfo?.account_preferences?.pincode
                        : "{Pincode}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                        : ""}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.email_address
                        ? ` ${userInfo?.account_preferences?.email_address}`
                        : ", {Email address}"}
                    </Form.Text>
                  </Row>
                  {/* {!isCreateVoucher && (
                    <Row>
                      <Col sm={6} className="text-start text-sm-end px-0 ">
                        <Form.Text className="text111 ">
                          VOUCHER NUMBER:
                        </Form.Text>
                      </Col>
                      <Col sm={6}>
                        <Form.Text
                          className="text11 p-0 "
                          style={{ lineHeight: "1.2" }}
                        >
                          {values?.voucher_no}
                        </Form.Text>

                        <Form.Control
                        type="text"
                        className="text11 p-0 shadow-none "
                        placeholder="Enter Invoice No."
                        required
                        value={values?.invoice_no}
                        readOnly
                      />
                      </Col>
                    </Row>
                  )}
                  <Row className="d-flex align-items-center mt-1">
                    <Col sm={6} className=" text-start text-sm-end px-0">
                      {" "}
                      <Form.Text className="text111 mt-0 ">
                        VOUCHER DATE:
                      </Form.Text>
                    </Col>
                    <Col sm={6}>
                      {" "}
                      {requestType === "edit" || isCreateVoucher ? (
                        <div className="text-end">
                          <DatePicker
                            wrapperClassName="datewrapper"
                            customInput={
                              <Form.Control
                                aria-describedby="basic-addon1"
                                className="my-1 text-end w-100"
                              />
                            }
                            required
                            maxDate={new Date()}
                            name="date"
                            id="date"
                            // change
                            dateFormat={
                              formatDate === null || formatDate === undefined
                                ? "dd MMM, yyyy"
                                : formatDate
                                    ?.replace(/D/g, "d")
                                    ?.replace(/Y/g, "y")
                            }
                            selected={
                              values?.date ? new Date(values?.date) : null // Updated to handle null date
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "date",
                                e ? moment(e).format("YYYY-MM-DD") : "" // Updated to handle empty date
                              );
                            }}
                            readOnly={
                              !(requestType === "edit" || isCreateVoucher)
                            }
                          />

                          <Form.Control.Feedback
                            className="d-block mb-1"
                            type="invalid"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        </div>
                      ) : (
                        <Form.Text className="text11">
                          {moment(values?.date).format(
                            `${
                              formatDate === null || formatDate === undefined
                                ? "dd MMM, yyyy"
                                : formatDate
                            }`
                          )}
                        </Form.Text>
                      )}
                    </Col>
                  </Row> */}
                </Col>
              </Row>

              <div className="mx-4 ">
                <Table striped hover className="my-4">
                  <thead>
                    <tr>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731" }}
                      >
                        Details
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "120px" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "120px" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "140px" }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateVoucher &&
                          values?.bill_description?.length > 1)) && (
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                          }}
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.bill_description?.map((row, index) => {
                      total +=
                        row.quantity || row.item_price
                          ? parseFloat(row.quantity * row.item_price)
                          : 0;
                      subTotal = total;
                      Quantity += row.quantity ? parseFloat(row.quantity) : 0;

                      {
                        /* appliedgst = (subTotal * (values?.has_gst || 0)) / 100;
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;

                      discountVal = (
                        parseFloat(subTotal) +
                        parseFloat(applyGST ? appliedgst : 0) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2); */
                      }
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;
                      netTotal = (
                        parseFloat(total) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);

                      {
                        /* appliedgst = (subTotal * (values?.has_gst || 0)) / 100;
                        applieddiscount =
                          (subTotal * (values?.has_discounts || 0)) / 100; */
                      }
                      appliedgst = applyDIS
                        ? (netTotal * (values?.has_gst || 0)) / 100
                        : (subTotal * (values?.has_gst || 0)) / 100;

                      discountVal = applyDIS
                        ? (
                            parseFloat(netTotal) +
                            parseFloat(applyGST ? appliedgst : 0)
                          ).toFixed(2)
                        : (
                            parseFloat(subTotal) +
                            parseFloat(applyGST ? appliedgst : 0)
                          ).toFixed(2);
                      return (
                        <tr
                          key={index}
                          style={{ height: "10px" }}
                          className="temp1row"
                        >
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                required
                                as="textarea"
                                name="item_description"
                                {...getFieldProps(
                                  `bill_description.${index}.item_description`
                                )}
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]
                                ?.item_description &&
                              errors.bill_description &&
                              errors.bill_description[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_price`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.item_price &&
                              errors.bill_description &&
                              errors.bill_description[index]?.item_price && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                maxLength={4}
                                required
                                type="text"
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.quantity &&
                              errors.bill_description &&
                              errors.bill_description[index]?.quantity && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                onKeyPress={numberonly}
                                value={(row.item_price * row.quantity).toFixed(
                                  2
                                )}
                                rows={1}
                                readOnly
                                name="Payments"
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {(row.item_price * row.quantity).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>
                          {(requestType === "edit" ||
                            (isCreateVoucher &&
                              values?.bill_description?.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          borderWidth: "1px",
                          padding: "1px 4px 4px 150px",
                        }}
                      >
                        {" "}
                        <Row className="d-flex align-items-center  mb-1">
                          <Col className="px-0">
                            <Form.Text className="text111 text-start">
                              Sub Total
                            </Form.Text>
                          </Col>
                        </Row>
                        {!(
                          requestType === "view" &&
                          !isCreateVoucher &&
                          !values?.has_discounts
                        ) && (
                          <Row className="m-0">
                            <Col sm={5} xs={5} className="ps-0">
                              {requestType === "view" && (
                                <Form.Label
                                  className="sizewt"
                                  style={{ color: "#454056", opacity: "0.5" }}
                                >
                                  Apply Discount
                                </Form.Label>
                              )}
                              {requestType !== "view" && (
                                <Form.Group controlId="applyDIS" md="auto">
                                  <Form.Check
                                    className="sizewt text-start mb-0"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateVoucher
                                      )
                                    }
                                    checked={applyDIS}
                                    onChange={() => setApplyDIS(!applyDIS)}
                                    type="checkbox"
                                    label="Apply Discount"
                                  />
                                </Form.Group>
                              )}
                            </Col>
                            {applyDIS && (
                              <>
                                <Col
                                  sm={3}
                                  xs={3}
                                  className=" d-flex flex-row pe-0  px-0 align-items-center ps-4"
                                >
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    {requestType === "edit" ||
                                    isCreateVoucher ? (
                                      <Form.Control
                                        className="gstdis shadow-none sizewt flex-row align-items-center"
                                        maxLength={3}
                                        type="number"
                                        readOnly={
                                          !(
                                            requestType === "edit" ||
                                            isCreateVoucher
                                          )
                                        }
                                        value={values?.has_discounts}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const nonNegativeValue =
                                            parseFloat(inputValue) < 0
                                              ? 0
                                              : inputValue;
                                          setFieldValue(
                                            "has_discounts",
                                            nonNegativeValue
                                          );
                                        }}
                                        name="disctval"
                                        style={{
                                          padding: "0.2rem",
                                          right: "5px",
                                          width: "40px",
                                          height: "1.45em",
                                          fontSize: "14px",
                                          textAlign: "end",
                                          borderRadius: "6px",
                                          marginRight: "-15px",
                                        }}
                                      />
                                    ) : (
                                      <Form.Text
                                        style={{
                                          fontSize: "15px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {values?.has_discounts}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;
                                  </div>
                                </Col>
                                {/* <Col sm={4} xs={4} className="text-end ">
                                <Form.Text className="sizewt">
                                  {`₹${applieddiscount.toFixed(2)}`}
                                </Form.Text>
                              </Col> */}
                              </>
                            )}
                          </Row>
                        )}
                        {applyDIS && (
                          <Row
                            className="d-flex align-items-center mb-1"
                            style={{ marginTop: "-3px" }}
                          >
                            <Col className="px-0">
                              <Form.Text className="text111 sizewt text-start">
                                Net Total
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                        {!(
                          requestType === "view" &&
                          !isCreateVoucher &&
                          !values?.has_gst
                        ) && (
                          <Row className="m-0">
                            <Col sm={5} xs={5} className="ps-0">
                              {requestType === "view" && (
                                <Form.Label
                                  className="sizewt"
                                  style={{ color: "#454056", opacity: "0.5" }}
                                >
                                  Apply GST
                                </Form.Label>
                              )}
                              {requestType !== "view" && (
                                <Form.Group controlId="ApplyGST" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    checked={applyGST}
                                    onChange={() => setApplyGST(!applyGST)}
                                    type="checkbox"
                                    label="Apply GST"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateVoucher
                                      )
                                    }
                                  />
                                </Form.Group>
                              )}
                            </Col>
                            {applyGST && (
                              <>
                                <Col
                                  sm={3}
                                  xs={3}
                                  className=" d-flex flex-row pe-0  px-0 align-items-center ps-4"
                                >
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    {requestType === "edit" ||
                                    isCreateVoucher ? (
                                      <Form.Control
                                        className="gstdis shadow-none sizewt flex-row align-items-center"
                                        name="has_gst"
                                        maxLength={5}
                                        type="number"
                                        value={values?.has_gst}
                                        readOnly={
                                          !(
                                            requestType === "edit" ||
                                            isCreateVoucher
                                          )
                                        }
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const nonNegativeValue =
                                            parseFloat(inputValue) < 0
                                              ? 0
                                              : inputValue;
                                          setFieldValue(
                                            "has_gst",
                                            nonNegativeValue
                                          );
                                        }}
                                        style={{
                                          padding: "0.2rem",
                                          right: "5px",
                                          width: "40px",
                                          height: "1.45em",
                                          fontSize: "14px",
                                          textAlign: "end",
                                          borderRadius: "6px",
                                          marginRight: "-15px",
                                        }}
                                      />
                                    ) : (
                                      <Form.Text
                                        style={{
                                          fontSize: "15px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {values?.has_gst}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;
                                  </div>
                                </Col>
                                {/* <Col sm={4} xs={4} className="text-end ">
                                <Form.Text className="sizewt">
                                  {`₹${appliedgst.toFixed(2)}`}
                                </Form.Text>
                              </Col> */}
                              </>
                            )}
                          </Row>
                        )}
                      </td>
                      <td
                        colSpan={
                          values.voucher_descriptions?.length > 1 ? 2 : 2
                        }
                        style={{
                          verticalAlign: "top",
                          paddingTop: "6px",
                          borderWidth: "1px",
                        }}
                      >
                        <Row
                          className=""
                          style={{ width: "100%", marginTop: "8px" }}
                        >
                          <Col className="text-start p-0">
                            <Form.Text className="sizewt">
                              {`₹${total.toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                        {applyDIS && (
                          <Row
                            style={{
                              width: "100%",
                              marginTop: applyGST ? "5px" : "5px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                        <Row className="">
                          <Col
                            className="text-start p-0"
                            style={{ marginTop: applyDIS ? "5px" : "0" }}
                          >
                            {applyDIS && (
                              <Form.Text className="sizewt">
                                {`₹${netTotal}`}
                              </Form.Text>
                            )}
                          </Col>
                        </Row>
                        {applyGST && (
                          <Row
                            style={{
                              width: "100%",
                              marginTop: applyDIS ? "5px" : "10px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          paddingLeft: "145px",
                          borderWidth: "1px",
                          backgroundColor: "#f7b731",
                        }}
                      >
                        <Row className="d-flex align-items-center">
                          <Col className="px-0">
                            <Form.Text className="total3 text-white">
                              Total
                            </Form.Text>
                          </Col>
                        </Row>
                      </td>
                      <td
                        colSpan={
                          values.voucher_descriptions?.length > 1 ? 2 : 2
                        }
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "#f7b731",
                        }}
                      >
                        <Row className="">
                          <Col className="p-0">
                            <Form.Text
                              className="total3 text-white"
                              style={{ textAlign: "" }}
                            >
                              {`₹${parseFloat(discountVal).toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                <Row className="mt-1">
                  <Col sm={6} className="ps-0">
                    <Form.Text className="text111 text-start p-0">
                      Mode of Payment :
                    </Form.Text>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to select the payment mode
                        </Tooltip>
                      }
                    >
                      {requestType === "edit" || isCreateVoucher ? (
                        <Form.Select
                          className=" mt-1"
                          style={{
                            maxWidth: "240px",
                            // fontWeight: "400",
                            fontSize: "15px",
                            marginBottom: "10px",
                          }}
                          required
                          name="payment_mode"
                          value={values?.payment_mode}
                          onChange={(e) => {
                            setFieldValue("payment_mode", e.target.value);
                          }}
                          disabled={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        >
                          <option value="">Select Payment Mode</option>
                          <option value="Bank">Bank</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>
                        </Form.Select>
                      ) : (
                        <Form.Text
                          className="text4  text-start"
                          style={{ width: "100%" }}
                        >
                          {values?.payment_mode}
                        </Form.Text>
                      )}
                    </OverlayTrigger>
                    {touched.payment_mode && errors.payment_mode && (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.payment_mode}
                      </Form.Control.Feedback>
                    )}
                    {values?.payment_mode !== "Cash" &&
                      values?.payment_mode !== "" && (
                        <Col className="pt-1">
                          <Form.Text className="text111 text-start p-0 mb-1">
                            {values?.payment_mode === "Bank"
                              ? "Transaction Id:"
                              : values?.payment_mode === "Cheque"
                              ? "Cheque Number:"
                              : values?.payment_mode === "Card"
                              ? "Enter last 4 digits of your card:"
                              : values?.payment_mode === "UPI"
                              ? "UPI Reference Id:"
                              : ""}
                          </Form.Text>
                          {requestType === "edit" || isCreateVoucher ? (
                            <Form.Control
                              style={{ maxWidth: "240px" }}
                              maxLength={25}
                              required
                              name="payment_mode_info"
                              readOnly={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                              value={values.payment_mode_info}
                              onChange={(e) => {
                                setFieldValue(
                                  "payment_mode_info",
                                  e.target.value
                                );
                              }}
                              placeholder={
                                values?.payment_mode === "Bank"
                                  ? "Enter the Transaction Id:-"
                                  : values?.payment_mode === "Cash"
                                  ? "Enter Amount:-"
                                  : values?.payment_mode === "Cheque"
                                  ? "Enter Cheque Number:-"
                                  : values?.payment_mode === "Card"
                                  ? "Enter last 4 digits of your card:-"
                                  : values?.payment_mode === "UPI"
                                  ? "Enter UPI Reference Id:-"
                                  : ""
                              }
                            />
                          ) : (
                            <Form.Text
                              className="text4  text-start"
                              style={{ width: "100%" }}
                            >
                              {values.payment_mode_info}
                            </Form.Text>
                          )}
                          {touched.payment_mode_info &&
                            errors.payment_mode_info && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.payment_mode_info}
                              </Form.Control.Feedback>
                            )}
                        </Col>
                      )}
                  </Col>
                  <Col className=" ps-0 mt-1" sm={6}>
                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.note
                    ) && (
                      <div style={{ width: "100%" }}>
                        <Form.Text className="text111 text-start mt-0">
                          Notes:
                        </Form.Text>
                        {requestType === "edit" || isCreateVoucher ? (
                          <AutoComplete
                            requestType="bills"
                            searchingFor="note"
                            callbackFieldValue={(e) =>
                              setFieldValue("note", e.target.value)
                            }
                            fieldValue={values.note}
                            className="my-1 w-100"
                            name="note"
                            id="note"
                            placeholder="Write something here"
                            readOnly={
                              !(requestType === "edit" || isCreateVoucher)
                            }
                          />
                        ) : (
                          <Form.Text
                            className="text4 border-0   text-start"
                            style={{ width: "100%" }}
                          >
                            {values && values?.note}
                          </Form.Text>
                        )}
                      </div>
                    )}
                  </Col>
                  {/* <Col sm={6} className="px-0">
                    <Row className="d-flex align-items-center">
                      <Col xs={5} sm={5} className="px-0">
                        <Form.Text className="text111 text-start">
                          Sub Total
                        </Form.Text>
                      </Col>

                      <Col xs={7} sm={7} className="text-end">
                        <Form.Text className="sizewt">
                          {`₹${total.toFixed(2)}`}
                        </Form.Text>
                      </Col>
                    </Row>

                    <Row className="my-1 d-flex align-items-center">
                      <Col sm={5} xs={5} className="px-0">
                        <Form.Text className="text111 text-start">
                          Amount in Words:
                        </Form.Text>
                      </Col>
                      <Col sm={7} xs={7} className="text-end ">
                        <Form.Text className=" sizewt  ">
                          {toWords.convert(parseInt(Math.round(total)))}
                        </Form.Text>
                      </Col>
                    </Row>
                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.has_discounts
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="ps-0">
                          <Form.Group controlId="applyDIS" md="auto">
                            <Form.Check
                              className="sizewt text-start mb-0"
                              disabled={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                              checked={applyDIS}
                              onChange={() => setApplyDIS(!applyDIS)}
                              type="checkbox"
                              label="Apply Discount"
                            />
                          </Form.Group>
                        </Col>
                        {applyDIS && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row pe-0  px-0 align-items-center"
                            >
                              <div className="d-flex flex-row sizewt align-items-center">
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  maxLength={3}
                                  type="number"
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  value={values?.has_discounts}
                                  // onChange={(e) => {
                                  //   setFieldValue(
                                  //     "has_discounts",
                                  //     e.target.value
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue(
                                      "has_discounts",
                                      nonNegativeValue
                                    );
                                  }}
                                  name="disctval"
                                />
                              </div>
                              <div className="d-flex flex-row sizewt align-items-center">
                                {" "}
                                <BsPercent className="" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {applyDIS && (
                        <>
                        <Row className="d-flex justify-content-between align-items-center ps-0">
                          <Col className="px-0" sm={6}>
                            <Form.Text className="d-flex justify-content-start text111 mt-0">
                              Net Total
                            </Form.Text>
                          </Col>
                          <Col className="" sm={6}>
                            <Form.Text className="d-flex justify-content-end sizewt mt-0">
                              {`₹${netTotal}`}
                            </Form.Text>
                          </Col>
                        </Row>
                        </>
                      )}
                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.has_gst
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="ps-0">
                          <Form.Group controlId="ApplyGST" md="auto">
                            <Form.Check
                              className="sizewt text-start"
                              checked={applyGST}
                              onChange={() => setApplyGST(!applyGST)}
                              type="checkbox"
                              label="Apply GST"
                              disabled={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                            />
                          </Form.Group>
                        </Col>
                        {applyGST && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row pe-0  px-0 align-items-center"
                            >
                              <div className="d-flex flex-row sizewt align-items-center">
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  name="has_gst"
                                  maxLength={5}
                                  type="number"
                                  value={values?.has_gst}
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  // onChange={(e) => {
                                  //   setFieldValue("has_gst", e.target.value);
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue("has_gst", nonNegativeValue);
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center flex-row sizewt">
                                {" "}
                                <BsPercent className="" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                  
                  </Col> */}
                </Row>
                {/* <Row className="mt-3 ">
                  <Col className="d-flex align-items-center ps-0" sm={6}>
                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.note
                    ) && (
                      <div style={{ width: "100%" }}>
                        <Form.Text className="text111 text-start mt-0">
                          Notes:
                        </Form.Text>
                        {requestType === "edit" || isCreateVoucher ? (
                          <AutoComplete
                            requestType="bills"
                            searchingFor="note"
                            callbackFieldValue={(e) =>
                              setFieldValue("note", e.target.value)
                            }
                            fieldValue={values.note}
                            className="my-1 w-100"
                            name="note"
                            id="note"
                            placeholder="Write something here"
                            readOnly={
                              !(requestType === "edit" || isCreateVoucher)
                            }
                          />
                        ) : (
                          <Form.Text
                            className="text4 border-0   text-start"
                            style={{ width: "100%" }}
                          >
                            {values && values?.note}
                          </Form.Text>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col
                    className="d-flex justify-content-between d-flex align-items-center"
                    sm={6}
                    style={{ backgroundColor: "#f7b731" }}
                  >
                    <Form.Text className="total1 ">Total</Form.Text>
                    <Form.Text className="total1 ">
                      {`₹${parseFloat(discountVal).toFixed(2)}`}
                    </Form.Text>
                  </Col>
                </Row> */}
              </div>
              {/* <Row className="text-center mt-3">
                {" "}
                <Form.Text>
                  {userInfo?.account_preferences?.address ||
                  userInfo?.account_preferences?.state ||
                  userInfo?.account_preferences?.pincode
                    ? `${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state} -  ${userInfo?.account_preferences?.pincode}`
                    : "{Company address - pincode}"}
                </Form.Text>
                <Form.Text>
                  {userInfo?.account_preferences?.contact_no
                    ? `${userInfo?.account_preferences?.contact_no}`
                    : "{Contact number}"}
                  {userInfo?.account_preferences?.alt_contact_no
                    ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                    : ""}
                  {userInfo?.account_preferences?.email_address
                    ? `, ${userInfo?.account_preferences?.email_address}`
                    : ", {Email address}"}
                </Form.Text>
              </Row> */}
            </div>
            {requestType === "view" && !isCreateVoucher && (
              <div style={{ marginTop: "100px" }}>
                <Row>
                  <Col>
                    <h6
                      className="text111 text-start ps-2"
                      style={{ color: "#454056" }}
                    >
                      Authorized By :
                      <div
                        style={{
                          marginLeft: "130px",
                          borderBottom: "2px solid #333",
                        }}
                      ></div>
                    </h6>
                  </Col>
                  <Col>
                    <h6
                      className="text111 text-start ps-0"
                      style={{ color: "#454056" }}
                    >
                      Recieved By :
                      <div
                        style={{
                          marginLeft: "110px",
                          borderBottom: "2px solid #333",
                        }}
                      ></div>
                    </h6>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          {
            <Row className=" position-sticky bottom-0 p-2 m-0 mt-3">
              {requestType === "edit" || isCreateVoucher ? (
                <>
                  {/* <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to change template
                        </Tooltip>
                      }
                    >
                      <Button
                        style={{ width: "100%" }}
                        variant="secondary"
                        onClick={() => {
                          if (requestType === "edit") {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/voucherTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateVoucher,
                              },
                            });
                          }
                        }}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col> */}
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to add one row below
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={addFields}
                      >
                        Add Row
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all voucher
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        style={{ width: "100%" }}
                        type="submit"
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                {/* Commented By Pulkit */}
                  {/* <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to change the template
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        if (!(requestType === "edit" || isCreateVoucher)) {
                          handleTemplatesChange(true);
                        } else {
                          navigate("/voucherTemplate", {
                            state: {
                              values: formik?.values,
                              requestType: requestType || isCreateVoucher,
                            },
                          });
                        }
                      }}
                    >
                      Change Template
                    </Button>
                  </OverlayTrigger>
                </Col>
                  {fromCashBook && (
                    <Col className="centerBtn">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">
                            Click to unlink the voucher from cashbook
                          </Tooltip>
                        }
                      >
                        <Button
                          id="pdf-button"
                          onClick={() => {
                            handleUnlinkVoucher();
                          }}
                          variant="primary"
                          style={{
                            width: "100%",
                            marginRight: "3px",
                          }}
                        >
                          Unlink
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  )}
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download the data in PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: ${values?.voucher_no}.pdf,
                          });
                        }}
                        variant={fromCashBook ? "secondary" : "primary"}
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print the data in PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        className=" w-100"
                        style={{
                          width: "100%",
                          marginLeft: "3px",
                          // backgroundColor: "#5e58d1",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </OverlayTrigger>
                  </Col> */}
                  <div
                    style={{
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <Col className="centerBtn">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">
                            Click to print the data in PDF
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          className="w-100"
                          style={{
                            width: "100%",
                            marginLeft: "3px",
                          }}
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    <Col className="centerBtn">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">
                            Click to download the data in PDF
                          </Tooltip>
                        }
                      >
                        <Button
                          id="pdf-button"
                          onClick={() => {
                            generatePDF(componentRef, {
                              filename: `${values?.voucher_no}.pdf`,
                            });
                          }}
                          variant={fromCashBook ? "secondary" : "secondary"}
                          style={{
                            width: "100%",
                            marginRight: "3px",
                          }}
                        >
                          Download
                        </Button>
                      </OverlayTrigger>
                    </Col>
                    {fromCashBook && (
                      <Col className="centerBtn">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip">
                              Click to unlink the voucher from cashbook
                            </Tooltip>
                          }
                        >
                          <Button
                            id="pdf-button"
                            onClick={() => {
                              handleUnlinkVoucher();
                            }}
                            variant="primary"
                            style={{
                              width: "100%",
                              marginRight: "3px",
                            }}
                          >
                            Unlink
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    )}
                    <Col className="centerBtn">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">
                            Click to change the template
                          </Tooltip>
                        }
                      >
                        <Button
                          // variant="secondary"
                          style={{ width: "100%", backgroundColor:"#FD5631" }}
                          onClick={() => {
                            if (!(requestType === "edit" || isCreateVoucher)) {
                              handleTemplatesChange(true);
                            } else {
                              navigate("/voucherTemplate", {
                                state: {
                                  values: formik?.values,
                                  requestType: requestType || isCreateVoucher,
                                },
                              });
                            }
                          }}
                        >
                          Change Template
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </div>
                </>
              )}
            </Row>
          }
        </Form>{" "}
      </Card>
    </div>
  );
}
