import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  Tab,
  Tabs,
  Badge,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import { RxCrossCircled } from "react-icons/rx";
import Head from "../components/NavBar/Head";
import Modals from "../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Location } from "../Utils/Locations";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { handleAccountPreferences } from "../store/Auth/AuthActions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FETCH_BANK_SUCCESS } from "../store/PaymentDetails/BankActionTypes";

export default function PaymentDetails({
  paymentDetailsEditable = false,
  setPaymentDetailsEditable,
}) {
  const [isModified, setIsModified] = useState(false);
  const [editPaymentDetails, setEditPaymentDetails] = useState(false);
  const [addNewPaymentDetails, setAddNewPaymentDetails] = useState(false);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const { userInfo } = useSelector((state) => state?.userInfo);
  const { paymentDetails } = useSelector((state) => state?.paymentDetails);
  ///*********************check payments details */
  console.log("The payment details is -->", paymentDetails);

  const [deletePayment, setDeletePayment] = useState({
    isOpen: false,
    msg: "",
  });
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    mode: Yup.string().required("Mode is required"),
    title: Yup.string().when("mode", {
      is: "other",
      then: Yup.string().required("Title is required").nullable(),
      otherwise: Yup.string(),
    }),
    description: Yup.string().when("mode", {
      is: "other",
      then: Yup.string().required("Description is required").nullable(),
      otherwise: Yup.string(),
    }),

    paymentID: Yup.string().when("mode", {
      is: (value) => value === "Paypal" || value === "UPI", // Check if mode is "Paypal" or "UPI"
      then: Yup.string()
        .required("Payment ID is required")
        .nullable()
        .email("Invalid Paypal ID format"), // Check if it's a valid email address
      // .test(
      //   "is-valid-payment-id",
      //   "Invalid Payment ID format",
      //   (value, context) => {
      //     const { mode } = context.parent; // Get the value of "mode" from the parent context
      //     // Validate based on the payment method
      //     if (mode === "Paypal") {
      //       return /^\d{10}$/i.test(value); // Paypal ID validation (example regex)
      //     } else if (mode === "UPI") {
      //       return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(
      //         value
      //       ); // UPI ID validation (example regex)
      //     }
      //     return false; // Return false if mode is neither "Paypal" nor "UPI"
      //   }
      // ),
      otherwise: Yup.string(),
    }),
    accountNumber: Yup.string().when("mode", {
      is: "bank",
      then: Yup.string()
        .required("Account Number is required")
        .matches(/^\d{10}$/, "Account Number must be 10 digits")
        .oneOf([Yup.ref("confirmAccountNumber")], "Account Numbers must match"),
      otherwise: Yup.string(),
    }),
    confirmAccountNumber: Yup.string().when("mode", {
      is: "bank",
      then: Yup.string()
        .required("Confirm Account Number is required")
        .matches(/^\d{10}$/, "Account Number must be 10 digits")
        .oneOf([Yup.ref("accountNumber")], "Account Numbers must match"),
      otherwise: Yup.string(),
    }),
    accountName: Yup.string().when("mode", {
      is: "bank",
      then: Yup.string().required("Account Name is required").nullable(),
      otherwise: Yup.string(),
    }),
    bank_name: Yup.string().when("mode", {
      is: "bank",
      then: Yup.string().required("Bank Name is required").nullable(),
      otherwise: Yup.string(),
    }),
    ifscCode: Yup.string().when("mode", {
      is: "bank",
      then: Yup.string()
        .required("IFSC Code is required")
        .nullable()
        .matches(
          /^[A-Za-z]{4}[0-9]{7}$/,
          "IFSC Code must be in the format XXXX0000000"
        ),
      otherwise: Yup.string(),
    }),
  });

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const getPaymentDataData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PAYMENT_DETAILS_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`, // Include the token in the header
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const filterData = res?.data?.data?.map((row) =>
            row?.mode === "bank"
              ? {
                  ...row,
                  confirmAccountNumber: row?.accountNumber,
                  isPrimary: row?.isPrimary === 1 ? true : false,
                }
              : { ...row, isPrimary: row?.isPrimary === 1 ? true : false }
          );
          dispatch({
            type: FETCH_BANK_SUCCESS,
            data: filterData,
          });
        }
        return res;
      })
      .catch((error) => error);
  };
  useEffect(() => {
    getPaymentDataData();
  }, []);
  
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isSubmitting, errors, },
  } = useForm({
    defaultValues: {
      mode: "bank",
      isPrimary: false,
    },
    resolver: yupResolver(validationSchema),
  });
  const watchedValues = watch();
  //console.log(errors);
  //console.log(getValues());

  const handleDeletePayment = async () => {
    setDeletePayment({
      ...deletePayment,
      isLoader: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_PAYMENT_DETAILS}`,
        {
          ids: [deletePayment?.id?.toString()],
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`, // Include the token in the header
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          getPaymentDataData();
          setShowPopup({
            ...showPopup,
            is: true,
            msg:
              res?.data?.message ||
              res?.response?.data?.message ||
              res?.message,
          });
          setDeletePayment({
            ...deletePayment,
            isOpen: false,
            id: "",
            msg: "",
          });
        }
      })
      .catch((error) => {
        setDeletePayment({
          ...deletePayment,
          isOpen: false,
          id: "",
          msg: "",
        });
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.message ||
            error?.message,
        });
        return error;
      });
  };
  const createOrUpdatePaymentDetails = async (data) => {
    if (
      paymentDetails?.filter((key) => key?.mode === data?.mode)?.length === 0
    ) {
      data = { ...data, isPrimary: true };
    }
    const {
      isPrimary,
      ifscCode,
      accountNumber,
      accountName,
      bank_name,
      swiftCode,
      title,
      description,
      paymentID,
      mode,
    } = data;
    const details =
      mode === "bank"
        ? {
            isPrimary,
            ifscCode,
            accountNumber,
            bank_name,
            accountName,
            swiftCode,
          }
        : mode === "UPI" || mode === "Paypal"
        ? { isPrimary, paymentID }
        : { isPrimary, title, description };

    const payload = data?.id
      ? {
          mode: data?.mode,
          id: data?.id,
          details,
        }
      : {
          mode: data?.mode,
          details,
        };
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CREATE_UPDATE_PAYMENT_DETAILS}`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setShowPopup({
            ...showPopup,
            is: true,
            msg:
              response?.response?.data?.message ||
              response?.data?.message ||
              response?.message,
          });
          getPaymentDataData();
          setAddNewPaymentDetails(false);
          setEditPaymentDetails(false);
          setIsModified(false);
          reset();
          return response;
        }
      })
      .catch((error) => {
        setAddNewPaymentDetails(false);
        setEditPaymentDetails(false);
        setIsModified(false);
        reset();
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.response?.data?.error ||
            error?.data?.error ||
            error?.message,
        });

        return error;
      });
  };

  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }
  const hideCharacters = (str, visibleChars = 4) => {
    if (str?.length > 4) {
      const visiblePart = str?.slice(-visibleChars);
      const hiddenPart = "*".repeat(str?.length - visibleChars);
      return hiddenPart + visiblePart;
    }
  };
  return (
    <div style={{ width: "100%", overflow: "auto", }} className="mb-3">
      <Card style={{ border: "none" }}>
        <Card.Body style={{ position: "relative" }}>
          <Row>
            <Col>
              <p className="fw-bold fs-6 mb-1">Payment Details</p>
            </Col>
          </Row>
          <hr className="mb-3" />
          <div style={{ position: "relative", width: "100%" }}>
            <Tabs
              id="controlled-tab-example"
              activeKey={watch("mode")}
              onSelect={(k) => {
                reset();
                setValue("mode", k);
                setEditPaymentDetails(false);
              }}
              className="mb-3"
            >
              <Tab eventKey="bank" title="Bank">
                {addNewPaymentDetails && (
            <Form>
              <Card className="mb-3">
                {watch("mode") === "bank" && (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-end">
                          <Form.Group
                            controlId="isPrimaryCheckbox"
                            className="mt-2"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Set entity as primary"
                              name="isPrimary"
                              value={watch("isPrimary")}
                              onChange={(e) =>
                                {setValue("isPrimary", e.target.checked);
                                  setIsModified(true);}
                              }
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <hr className="mb-3" />
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="accountNumber"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Account Number
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="accountNumber"
                            placeholder="Enter Account Number"
                            className="rounded"
                            onKeyPress={numberonly}
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("accountNumber")}
                            onChange={(e) =>
                              {setValue("accountNumber", e.target.value)
                              setIsModified(true);}
                            }
                            isInvalid={!!errors.accountNumber?.message}
                          />
                          {errors?.accountNumber &&
                            errors?.accountNumber?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.accountNumber?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="confirmAccountNumber"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Confirm Account Number
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="confirmAccountNumber"
                            placeholder="Enter Confirm Account Number"
                            onKeyPress={numberonly}
                            style={{ fontSize: "15px", color: "#666276" }}
                            isInvalid={!!errors.confirmAccountNumber?.message}
                            onChange={(e) =>
                              {setValue("confirmAccountNumber", e.target.value)
                              setIsModified(true);}
                            }
                            {...register("confirmAccountNumber")}
                          />
                          {errors?.confirmAccountNumber &&
                            errors?.confirmAccountNumber?.message !==
                              undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.confirmAccountNumber?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="ifscCode"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          IFSC Code
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="ifscCode"
                            placeholder="Enter IFSC Code"
                            onChange={(e) =>
                              {setValue("ifscCode", e.target.value)
                              setIsModified(true);}
                            }
                            style={{ fontSize: "15px", color: "#666276" }}
                          />
                          {errors?.ifscCode &&
                            errors?.ifscCode?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.ifscCode?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="">
                      {/**Add Bank Name */}
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="bank_name"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Bank Name
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="bank_name"
                            className="rounded"
                            placeholder="Enter Bank Number"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("bank_name")}
                            isInvalid={!!errors.bank_name?.message}
                            onChange={(e) =>
                              {setValue("bank_name", e.target.value)
                              setIsModified(true);}
                            }
                          />
                          {errors?.accountName &&
                            errors?.accountName?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.bank_name?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>

                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="accountName"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Account Name
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="accountName"
                            className="rounded"
                            placeholder="Enter Account Number"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("accountName")}
                            isInvalid={!!errors.accountName?.message}
                            onChange={(e) =>
                              {setValue("accountName", e.target.value)
                              setIsModified(true);}
                            }
                          />
                          {errors?.accountName &&
                            errors?.accountName?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.accountName?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="swiftCode"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Swift Code (Optional)
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="swiftCode"
                            placeholder="Enter Swift Code"
                            {...register("swiftCode")}
                            isInvalid={!!errors.swiftCode?.message}
                            onChange={(e) =>
                              {setValue("swiftCode", e.target.value)
                              setIsModified(true);}
                            }
                          />
                          {errors?.swiftCode &&
                            errors?.swiftCode?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.swiftCode?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      {/* <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              setValue("isPrimary", e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  </Card.Body>
                )}
                <Card.Footer className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
                    }
                  >
                    <Button
                      className="me-3 btn-sm"
                      variant="secondary"
                      onClick={() => {
                        setAddNewPaymentDetails(false);
                        setIsModified(false);
                        reset();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Save the entry</Tooltip>
                    }
                  >
                    <Button
                      className="btn-sm"
                      type="Submit"
                      variant="primary"
                      onClick={handleSubmit(createOrUpdatePaymentDetails)}
                      disabled={!isModified || isSubmitting}
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
                </Card>
                </Form>)
                }
                <Form>
                  {paymentDetails?.filter((key) => key?.mode === "bank")
                    ?.length > 0
                    ? paymentDetails
                        ?.filter((key) => key?.mode === "bank")
                        .map((row, index) => (
                          <Card className="mb-3" key={index}>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <p className="fw-bold fs-6 mb-1">
                                    {hideCharacters(row?.accountNumber)}{" "}
                                    {row?.isPrimary ? (
                                      <Badge bg="success">Primary</Badge>
                                    ) : null}
                                  </p>
                                </Col>
                                <Col className="text-end">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Click to edit the form
                                      </Tooltip>
                                    }
                                  >
                                    <MdDeleteOutline
                                      className={"fs-4 text-danger"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setDeletePayment({
                                          ...deletePayment,
                                          isOpen: true,
                                          id: row?.id,
                                          msg: row?.isPrimary
                                            ? "Please add another account as the primary one, then you can remove this payment method."
                                            : `Are you sure you want to delete ${hideCharacters(
                                                row?.accountNumber
                                              )} payment detail?`,
                                        });
                                      }}
                                    />
                                  </OverlayTrigger>
                                  {editPaymentDetails !== index ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Click to edit the form
                                        </Tooltip>
                                      }
                                    >
                                      <RiPencilFill
                                        className={"fs-4 text-primary"}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "25px",
                                        }}
                                        onClick={() => {
                                          setEditPaymentDetails(index);
                                          row &&
                                            Object.entries(row)
                                              ?.filter(
                                                ([value]) => value !== null
                                              )
                                              .forEach(([key, value]) => {
                                                setValue(key, value);
                                              });
                                        }}
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <hr className="mb-3" />
                                <Col md={4} sm={12}>
                                  <Form.Label
                                    htmlFor="accountNumber"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Account Number
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.accountNumber ? (
                                          row?.accountNumber
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="accountNumber"
                                        placeholder="Enter Account Number"
                                        className="rounded"
                                        onKeyPress={numberonly}
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("accountNumber")}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "accountNumber",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                        isInvalid={
                                          !!errors.accountNumber?.message
                                        }
                                      />
                                      {errors?.accountNumber &&
                                        errors?.accountNumber?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.accountNumber?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>{" "}
                                {editPaymentDetails === index && (
                                  <Col md={4} sm={12}>
                                    <Form.Label
                                      htmlFor="confirmAccountNumber"
                                      className="mb-0"
                                      style={{ color: "#666276" }}
                                    >
                                      {editPaymentDetails === index
                                        ? "Confirm Account Number "
                                        : ""}
                                    </Form.Label>
                                    {editPaymentDetails !== index ? (
                                      <Form.Text>
                                        <p style={{ fontSize: "15px" }}>
                                          {row?.confirmAccountNumber ? (
                                            row?.confirmAccountNumber
                                          ) : (
                                            <>&#8212;</>
                                          )}
                                        </p>
                                      </Form.Text>
                                    ) : (
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          id="confirmAccountNumber"
                                          placeholder="Enter Confirm Account Number"
                                          onKeyPress={numberonly}
                                          style={{
                                            fontSize: "15px",
                                            color: "#666276",
                                          }}
                                          isInvalid={
                                            !!errors.confirmAccountNumber
                                              ?.message
                                          }
                                          {...register("confirmAccountNumber")}
                                          onChange={(e) =>
                                          {
                                            setValue(
                                            "confirmAccountNumber",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                          }
                                        />
                                        {errors?.confirmAccountNumber &&
                                          errors?.confirmAccountNumber
                                            ?.message !== undefined && (
                                            <Form.Control.Feedback
                                              className="d-block "
                                              type="invalid"
                                            >
                                              {
                                                errors?.confirmAccountNumber
                                                  ?.message
                                              }
                                            </Form.Control.Feedback>
                                          )}
                                      </InputGroup>
                                    )}
                                  </Col>
                                )}
                                <Col md={4} sm={12}>
                                  <Form.Label
                                    htmlFor="ifscCode"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    IFSC Code
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.ifscCode ? (
                                          row?.ifscCode
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="ifscCode"
                                        placeholder="Enter IFSC Code"
                                        {...register("ifscCode")}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "ifscCode",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                      />
                                      {errors?.ifscCode &&
                                        errors?.ifscCode?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.ifscCode?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                                <Col md={4} sm={12}>
                                  <Form.Label
                                    htmlFor="bank_name"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Bank Name
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.bank_name ? (
                                          row?.bank_name
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="bank_name"
                                        className="rounded"
                                        placeholder="Enter Bank Number"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("bank_name")}
                                        isInvalid={!!errors.bank_name?.message}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "bank_name",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                      />
                                      {errors?.bank_name &&
                                        errors?.bank_name?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.bank_name?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                              {/* </Row>
                              <Row className=""> */}
                                {/**Add Bank name */}

                                <Col md={4} sm={12}>
                                  <Form.Label
                                    htmlFor="accountName"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Account Name
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.accountName ? (
                                          row?.accountName
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="accountName"
                                        className="rounded"
                                        placeholder="Enter Account Number"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("accountName")}
                                        isInvalid={
                                          !!errors.accountName?.message
                                        }
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "accountName",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                      />
                                      {errors?.accountName &&
                                        errors?.accountName?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.accountName?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                                <Col md={4} sm={12}>
                                  <Form.Label
                                    htmlFor="swiftCode"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Swift Code (Optional)
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.swiftCode ? (
                                          row?.swiftCode
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="swiftCode"
                                        placeholder="Enter Swift Code"
                                        {...register("swiftCode")}
                                        isInvalid={!!errors.swiftCode?.message}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "swiftCode",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                      />
                                      {errors?.swiftCode &&
                                        errors?.swiftCode?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.swiftCode?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                                {editPaymentDetails === index && (
                                  <Col sm={12}>
                                    <OverlayTrigger
                                      placement="left-end"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          {row?.isPrimary === true
                                            ? "Make other entiry to as primary"
                                            : "Set this as primary"}
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Group
                                        controlId="isPrimaryCheckbox"
                                        className="mt-2"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Set entity as primary"
                                          name="isPrimary"
                                          disabled={row?.isPrimary === true}
                                          checked={watch("isPrimary")}
                                          onChange={(e) =>
                                            {setValue(
                                              "isPrimary",
                                              e.target.checked
                                            );
                                            setIsModified(true);}
                                          }
                                        />
                                      </Form.Group>
                                    </OverlayTrigger>
                                  </Col>
                                )}
                              </Row>
                            </Card.Body>
                            <Card.Footer
                              className={`d-flex justify-content-end ${
                                editPaymentDetails !== index
                                  ? "d-none"
                                  : "d-block"
                              }`}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Cancel the changes
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="me-3 btn-sm"
                                  variant="secondary"
                                  onClick={() => {
                                    setEditPaymentDetails(false);
                                    setIsModified(false);
                                    reset();
                                  }}
                                  disabled={isSubmitting}
                                >
                                  Cancel
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Save the entry
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-sm"
                                  type="Submit"
                                  variant="primary"
                                  onClick={handleSubmit(
                                    createOrUpdatePaymentDetails
                                  )}
                                  // disabled={isSubmitting}
                                  disabled={!isModified || isSubmitting}
                                >
                                  Save
                                </Button>
                              </OverlayTrigger>
                            </Card.Footer>
                          </Card>
                        ))
                    : null}
                </Form>
              </Tab>
              <Tab eventKey="Paypal" title="Paypal">
              {addNewPaymentDetails && (
              <Form>
                <Card className="mb-3">
                  {watch("mode") === "Paypal" && (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>
                      <Col>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              {setValue("isPrimary", e.target.checked);
                                setIsModified(true);}
                            }
                          />
                        </Form.Group>
                      </Col>

                      <hr className="mb-3" />
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="paymentId"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          {"Paypal ID"}
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="paymentId"
                            placeholder={"Enter Paypal ID"}
                            className="rounded"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("paymentID")}
                            onChange={(e) =>
                              {setValue("paymentID", e.target.value)
                              setIsModified(true);}
                            }
                            isInvalid={!!errors.paymentID?.message}
                          />
                          {errors?.paymentID &&
                            errors?.paymentID?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.paymentID?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              {setValue("isPrimary", e.target.checked);
                                setIsModified(true);}
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>)}
                  <Card.Footer className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
                    }
                  >
                    <Button
                      className="me-3 btn-sm"
                      variant="secondary"
                      onClick={() => {
                        setAddNewPaymentDetails(false);
                        setIsModified(false);
                        reset();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Save the entry</Tooltip>
                    }
                  >
                    <Button
                      className="btn-sm"
                      type="Submit"
                      variant="primary"
                      onClick={handleSubmit(createOrUpdatePaymentDetails)}
                      disabled={!isModified || isSubmitting}
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
                </Card>
              </Form>
              )}
                <Form>
                  {paymentDetails?.filter((key) => key?.mode === "Paypal")
                    ?.length > 0
                    ? paymentDetails
                        ?.filter((key) => key?.mode === "Paypal")
                        .map((row, index) => (
                          <Card className="mb-3" key={index}>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <p className="fw-bold fs-6 mb-1">
                                    {hideCharacters(row?.paymentID)}{" "}
                                    {row?.isPrimary ? (
                                      <Badge bg="success">Primary</Badge>
                                    ) : null}
                                  </p>
                                </Col>
                                <Col className="text-end">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Click to edit the form
                                      </Tooltip>
                                    }
                                  >
                                    <MdDeleteOutline
                                      className={"fs-4 text-danger"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setDeletePayment({
                                          ...deletePayment,
                                          isOpen: true,
                                          id: row?.id,

                                          msg: row?.isPrimary
                                            ? "Please create another account as the primary one, then you can remove this payment method."
                                            : `Are you sure you want to delete ${hideCharacters(
                                                row?.paymentID
                                              )} payment detail?`,
                                        });
                                      }}
                                    />
                                  </OverlayTrigger>
                                  {editPaymentDetails !== index ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Click to edit the form
                                        </Tooltip>
                                      }
                                    >
                                      <RiPencilFill
                                        className={"fs-4 text-primary"}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "25px",
                                        }}
                                        onClick={() => {
                                          setEditPaymentDetails(index);
                                          row &&
                                            Object.entries(row)
                                              ?.filter(
                                                ([value]) => value !== null
                                              )
                                              .forEach(([key, value]) => {
                                                setValue(key, value);
                                              });
                                        }}
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <hr className="mb-3" />
                                <Col md={6} sm={12}>
                                  <Form.Label
                                    htmlFor="paymentId"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Paypal ID
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.paymentID ? (
                                          row?.paymentID
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="paymentId"
                                        placeholder="Enter Paypal ID"
                                        className="rounded"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("paymentID")}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "paymentID",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                        isInvalid={!!errors.paymentID?.message}
                                      />
                                      {errors?.paymentID &&
                                        errors?.paymentID?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.paymentID?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>{" "}
                                {editPaymentDetails === index && (
                                  <Col sm={12}>
                                    <OverlayTrigger
                                      placement="left-end"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          {row?.isPrimary === true
                                            ? "Make other entiry to as primary"
                                            : "Set this as primary"}
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Group
                                        controlId="isPrimaryCheckbox"
                                        className="mt-2"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Set entity as primary"
                                          name="isPrimary"
                                          disabled={row?.isPrimary === true}
                                          checked={watch("isPrimary")}
                                          onChange={(e) =>
                                            {setValue(
                                              "isPrimary",
                                              e.target.checked
                                            );
                                            setIsModified(true);}
                                          }
                                        />
                                      </Form.Group>
                                    </OverlayTrigger>
                                  </Col>
                                )}
                              </Row>
                            </Card.Body>
                            <Card.Footer
                              className={`d-flex justify-content-end ${
                                editPaymentDetails !== index
                                  ? "d-none"
                                  : "d-block"
                              }`}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Cancel the changes
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="me-3 btn-sm"
                                  variant="secondary"
                                  onClick={() => {
                                    setEditPaymentDetails(false);
                                    setIsModified(false);
                                    reset();
                                  }}
                                  disabled={isSubmitting}
                                >
                                  Cancel
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Save the entry
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-sm"
                                  type="Submit"
                                  variant="primary"
                                  onClick={handleSubmit(
                                    createOrUpdatePaymentDetails
                                  )}
                                  disabled={!isModified || isSubmitting}
                                >
                                  Save
                                </Button>
                              </OverlayTrigger>
                            </Card.Footer>
                          </Card>
                        ))
                    : null}
                </Form>
              </Tab>
              <Tab eventKey="UPI" title="UPI">
              {addNewPaymentDetails && (
              <Form>
                <Card className="mb-3">
                  {watch("mode") === "Paypal" || watch("mode") === "UPI" && (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>
                      <Col>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              {setValue("isPrimary", e.target.checked);
                                setIsModified(true);}
                            }
                          />
                        </Form.Group>
                      </Col>

                      <hr className="mb-3" />
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="paymentId"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          {"UPI ID"}
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="paymentId"
                            placeholder={"Enter UPI ID"}
                            className="rounded"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("paymentID")}
                            onChange={(e) =>
                              {setValue("paymentID", e.target.value)
                              setIsModified(true);}
                            }
                            isInvalid={!!errors.paymentID?.message}
                          />
                          {errors?.paymentID &&
                            errors?.paymentID?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.paymentID?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              {setValue("isPrimary", e.target.checked);
                                setIsModified(true);}
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>)}
                  <Card.Footer className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
                    }
                  >
                    <Button
                      className="me-3 btn-sm"
                      variant="secondary"
                      onClick={() => {
                        setAddNewPaymentDetails(false);
                        setIsModified(false);
                        reset();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Save the entry</Tooltip>
                    }
                  >
                    <Button
                      className="btn-sm"
                      type="Submit"
                      variant="primary"
                      onClick={handleSubmit(createOrUpdatePaymentDetails)}
                      disabled={!isModified || isSubmitting}
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
                </Card>
              </Form>
              )}
                <Form>
                  {paymentDetails?.filter((key) => key?.mode === "UPI")
                    ?.length > 0
                    ? paymentDetails
                        ?.filter((key) => key?.mode === "UPI")
                        .map((row, index) => (
                          <Card className="mb-3" key={index}>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <p className="fw-bold fs-6 mb-1">
                                    {hideCharacters(row?.paymentID)}{" "}
                                    {row?.isPrimary ? (
                                      <Badge bg="success">Primary</Badge>
                                    ) : null}
                                  </p>
                                </Col>
                                <Col className="text-end">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Click to edit the form
                                      </Tooltip>
                                    }
                                  >
                                    <MdDeleteOutline
                                      className={"fs-4 text-danger"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setDeletePayment({
                                          ...deletePayment,
                                          isOpen: true,
                                          id: row?.id,

                                          msg: row?.isPrimary
                                            ? "Please create another account as the primary one, then you can remove this payment method."
                                            : `Are you sure you want to delete ${hideCharacters(
                                                row?.paymentID
                                              )} payment detail?`,
                                        });
                                      }}
                                    />
                                  </OverlayTrigger>
                                  {editPaymentDetails !== index ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Click to edit the form
                                        </Tooltip>
                                      }
                                    >
                                      <RiPencilFill
                                        className={"fs-4 text-primary"}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "25px",
                                        }}
                                        onClick={() => {
                                          setEditPaymentDetails(index);
                                          row &&
                                            Object.entries(row)
                                              ?.filter(
                                                ([value]) => value !== null
                                              )
                                              .forEach(([key, value]) => {
                                                setValue(key, value);
                                              });
                                        }}
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <hr className="mb-3" />
                                <Col md={6} sm={12}>
                                  <Form.Label
                                    htmlFor="paymentId"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    UPI ID
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.paymentID ? (
                                          row?.paymentID
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="paymentId"
                                        placeholder="Enter UPI ID"
                                        className="rounded"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("paymentID")}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "paymentID",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                        isInvalid={!!errors.paymentID?.message}
                                      />
                                      {errors?.paymentID &&
                                        errors?.paymentID?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.paymentID?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>{" "}
                                {editPaymentDetails === index && (
                                  <Col sm={12}>
                                    <OverlayTrigger
                                      placement="left-end"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          {row?.isPrimary === true
                                            ? "Make other entiry to as primary"
                                            : "Set this as primary"}
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Group
                                        controlId="isPrimaryCheckbox"
                                        className="mt-2"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Set entity as primary"
                                          name="isPrimary"
                                          disabled={row?.isPrimary === true}
                                          checked={watch("isPrimary")}
                                          onChange={(e) =>
                                            {setValue(
                                              "isPrimary",
                                              e.target.checked
                                            );
                                            setIsModified(true);}
                                          }
                                        />
                                      </Form.Group>
                                    </OverlayTrigger>
                                  </Col>
                                )}
                              </Row>
                            </Card.Body>
                            <Card.Footer
                              className={`d-flex justify-content-end ${
                                editPaymentDetails !== index
                                  ? "d-none"
                                  : "d-block"
                              }`}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Cancel the changes
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="me-3 btn-sm"
                                  variant="secondary"
                                  onClick={() => {
                                    setEditPaymentDetails(false);
                                    setIsModified(false);
                                    reset();
                                  }}
                                  disabled={!isModified || isSubmitting}
                                >
                                  Cancel
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Save the entry
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-sm"
                                  type="Submit"
                                  variant="primary"
                                  onClick={handleSubmit(
                                    createOrUpdatePaymentDetails
                                  )}
                                  disabled={!isModified || isSubmitting}
                                >
                                  Save
                                </Button>
                              </OverlayTrigger>
                            </Card.Footer>
                          </Card>
                        ))
                    : null}
                </Form>
              </Tab>
              <Tab eventKey="other" title="Other">
              {addNewPaymentDetails && (
              <Form>
                <Card className="mb-3">
                  {watch("mode") === "other" && (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>

                      <hr className="mb-3" />
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="title"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Title
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="title"
                            placeholder="Enter Title"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("title")}
                            onChange={(e) => {setValue("title", e.target.value);setIsModified(true);}}
                            isInvalid={!!errors.title?.message}
                          />
                          {errors?.title &&
                            errors?.title?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.title?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="description"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Description
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="description"
                            placeholder="Enter Description"
                            style={{ fontSize: "15px", color: "#666276" }}
                            isInvalid={!!errors.description?.message}
                            onChange={(e) =>
                              {setValue("description", e.target.value)
                              setIsModified(true);}
                            }
                            {...register("description")}
                          />
                          {errors?.description &&
                            errors?.description?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.description?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              {setValue("isPrimary", e.target.checked);
                                setIsModified(true);}
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
              )}
              <Card.Footer className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
                    }
                  >
                    <Button
                      className="me-3 btn-sm"
                      variant="secondary"
                      onClick={() => {
                        setAddNewPaymentDetails(false);
                        setIsModified(false);
                        reset();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Save the entry</Tooltip>
                    }
                  >
                    <Button
                      className="btn-sm"
                      type="Submit"
                      variant="primary"
                      onClick={handleSubmit(createOrUpdatePaymentDetails)}
                      disabled={!isModified || isSubmitting}
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
              </Card>
              </Form>)}
                <Form>
                  {paymentDetails?.filter((key) => key?.mode === "other")
                    ?.length > 0
                    ? paymentDetails
                        ?.filter((key) => key?.mode === "other")
                        .map((row, index) => (
                          <Card className="mb-3" key={index}>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <p className="fw-bold fs-6 mb-1">
                                    {hideCharacters(row?.title)}{" "}
                                    {row?.isPrimary ? (
                                      <Badge bg="success">Primary</Badge>
                                    ) : null}
                                  </p>
                                </Col>
                                <Col className="text-end">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        Click to edit the form
                                      </Tooltip>
                                    }
                                  >
                                    <MdDeleteOutline
                                      className={"fs-4 text-danger"}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setDeletePayment({
                                          ...deletePayment,
                                          isOpen: true,
                                          id: row?.id,

                                          msg: row?.isPrimary
                                            ? "Please create another account as the primary one, then you can remove this payment method."
                                            : `Are you sure you want to delete ${hideCharacters(
                                                row?.title
                                              )} payment detail?`,
                                        });
                                      }}
                                    />
                                  </OverlayTrigger>
                                  {editPaymentDetails !== index ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          Click to edit the form
                                        </Tooltip>
                                      }
                                    >
                                      <RiPencilFill
                                        className={"fs-4 text-primary"}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "25px",
                                        }}
                                        onClick={() => {
                                          setEditPaymentDetails(index);
                                          row &&
                                            Object.entries(row)
                                              ?.filter(
                                                ([value]) => value !== null
                                              )
                                              .forEach(([key, value]) => {
                                                setValue(key, value);
                                              });
                                        }}
                                      />
                                    </OverlayTrigger>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <hr className="mb-3" />
                                <Col md={6} sm={12}>
                                  <Form.Label
                                    htmlFor="title"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Title
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.title ? row?.title : <>&#8212;</>}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="title"
                                        placeholder="Enter Title"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        {...register("title")}
                                        onChange={(e) =>
                                          {
                                            setValue(
                                            "title",
                                            e.target.value
                                          )
                                          setIsModified(JSON.stringify(paymentDetails[index]) !== JSON.stringify(watchedValues));
                                          }
                                        }
                                        isInvalid={!!errors.title?.message}
                                      />
                                      {errors?.title &&
                                        errors?.title?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.title?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                                <Col md={6} sm={12}>
                                  <Form.Label
                                    htmlFor="description"
                                    className="mb-0"
                                    style={{ color: "#666276" }}
                                  >
                                    Description
                                  </Form.Label>
                                  {editPaymentDetails !== index ? (
                                    <Form.Text>
                                      <p style={{ fontSize: "15px" }}>
                                        {row?.description ? (
                                          row?.description
                                        ) : (
                                          <>&#8212;</>
                                        )}
                                      </p>
                                    </Form.Text>
                                  ) : (
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        id="description"
                                        placeholder="Enter Description"
                                        style={{
                                          fontSize: "15px",
                                          color: "#666276",
                                        }}
                                        isInvalid={
                                          !!errors.description?.message
                                        }
                                        onChange={(e) =>
                                          setValue(
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        {...register("description")}
                                      />
                                      {errors?.description &&
                                        errors?.description?.message !==
                                          undefined && (
                                          <Form.Control.Feedback
                                            className="d-block "
                                            type="invalid"
                                          >
                                            {errors?.description?.message}
                                          </Form.Control.Feedback>
                                        )}
                                    </InputGroup>
                                  )}
                                </Col>
                                {editPaymentDetails === index && (
                                  <Col sm={12}>
                                    <OverlayTrigger
                                      placement="left-end"
                                      overlay={
                                        <Tooltip id="button-tooltip">
                                          {row?.isPrimary === true
                                            ? "Make other entiry to as primary"
                                            : "Set this as primary"}
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Group
                                        controlId="isPrimaryCheckbox"
                                        className="mt-2"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Set entity as primary"
                                          name="isPrimary"
                                          disabled={row?.isPrimary === true}
                                          checked={watch("isPrimary")}
                                          onChange={(e) =>
                                            {setValue(
                                              "isPrimary",
                                              e.target.checked
                                            );
                                            setIsModified(true);}
                                          }
                                        />
                                      </Form.Group>
                                    </OverlayTrigger>
                                  </Col>
                                )}
                              </Row>
                            </Card.Body>
                            <Card.Footer
                              className={`d-flex justify-content-end ${
                                editPaymentDetails !== index
                                  ? "d-none"
                                  : "d-block"
                              }`}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Cancel the changes
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="me-3 btn-sm"
                                  variant="secondary"
                                  onClick={() => {
                                    setEditPaymentDetails(false);
                                    setIsModified(false);
                                    reset();
                                  }}
                                  disabled={isSubmitting}
                                >
                                  Cancel
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Save the entry
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-sm"
                                  type="Submit"
                                  variant="primary"
                                  onClick={handleSubmit(
                                    createOrUpdatePaymentDetails
                                  )}
                                  disabled={!isModified || isSubmitting}
                                >
                                  Save
                                </Button>
                              </OverlayTrigger>
                            </Card.Footer>
                          </Card>
                        ))
                    : null}
                </Form>
              </Tab>
            </Tabs>
            <Button
              variant="primary"
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={() => {
                setAddNewPaymentDetails(true);
              }}
              disabled={addNewPaymentDetails}
            >
              Add Account
            </Button>
          </div>
          {/* Code Commented By Pulkit */}
          {/* {addNewPaymentDetails && (
            <Form>
              <Card className="mb-3">
                {watch("mode") === "bank" ? (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-end">
                          <Form.Group
                            controlId="isPrimaryCheckbox"
                            className="mt-2"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Set entity as primary"
                              name="isPrimary"
                              value={watch("isPrimary")}
                              onChange={(e) =>
                                setValue("isPrimary", e.target.checked)
                              }
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <hr className="mb-3" />
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="accountNumber"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Account Number
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="accountNumber"
                            placeholder="Enter Account Number"
                            className="rounded"
                            onKeyPress={numberonly}
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("accountNumber")}
                            onChange={(e) =>
                              setValue("accountNumber", e.target.value)
                            }
                            isInvalid={!!errors.accountNumber?.message}
                          />
                          {errors?.accountNumber &&
                            errors?.accountNumber?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.accountNumber?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="confirmAccountNumber"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Confirm Account Number
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="confirmAccountNumber"
                            placeholder="Enter Confirm Account Number"
                            onKeyPress={numberonly}
                            style={{ fontSize: "15px", color: "#666276" }}
                            isInvalid={!!errors.confirmAccountNumber?.message}
                            onChange={(e) =>
                              setValue("confirmAccountNumber", e.target.value)
                            }
                            {...register("confirmAccountNumber")}
                          />
                          {errors?.confirmAccountNumber &&
                            errors?.confirmAccountNumber?.message !==
                              undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.confirmAccountNumber?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="ifscCode"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          IFSC Code
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="ifscCode"
                            placeholder="Enter IFSC Code"
                            onChange={(e) =>
                              setValue("ifscCode", e.target.value)
                            }
                            style={{ fontSize: "15px", color: "#666276" }}
                          />
                          {errors?.ifscCode &&
                            errors?.ifscCode?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.ifscCode?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="">
                      *Add Bank Name
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="bank_name"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Bank Name
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="bank_name"
                            className="rounded"
                            placeholder="Enter Bank Number"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("bank_name")}
                            isInvalid={!!errors.bank_name?.message}
                            onChange={(e) =>
                              setValue("bank_name", e.target.value)
                            }
                          />
                          {errors?.accountName &&
                            errors?.accountName?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.bank_name?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>

                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="accountName"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Account Name
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="accountName"
                            className="rounded"
                            placeholder="Enter Account Number"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("accountName")}
                            isInvalid={!!errors.accountName?.message}
                            onChange={(e) =>
                              setValue("accountName", e.target.value)
                            }
                          />
                          {errors?.accountName &&
                            errors?.accountName?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.accountName?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <Form.Label
                          htmlFor="swiftCode"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Swift Code (Optional)
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="swiftCode"
                            placeholder="Enter Swift Code"
                            {...register("swiftCode")}
                            isInvalid={!!errors.swiftCode?.message}
                            onChange={(e) =>
                              setValue("swiftCode", e.target.value)
                            }
                          />
                          {errors?.swiftCode &&
                            errors?.swiftCode?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.swiftCode?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              setValue("isPrimary", e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                ) : watch("mode") === "Paypal" || watch("mode") === "UPI" ? (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>
                      <Col>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              setValue("isPrimary", e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col>

                      <hr className="mb-3" />
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="paymentId"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          {watch("mode") === "Paypal" ? "Paypal ID" : "UPI ID"}
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="paymentId"
                            placeholder={
                              watch("mode") === "Paypal"
                                ? "Enter Paypal ID"
                                : "Enter UPI ID"
                            }
                            className="rounded"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("paymentID")}
                            onChange={(e) =>
                              setValue("paymentID", e.target.value)
                            }
                            isInvalid={!!errors.paymentID?.message}
                          />
                          {errors?.paymentID &&
                            errors?.paymentID?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.paymentID?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              setValue("isPrimary", e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="fw-bold fs-6 mb-1">Add new details</p>
                      </Col>

                      <hr className="mb-3" />
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="title"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Title
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="title"
                            placeholder="Enter Title"
                            style={{ fontSize: "15px", color: "#666276" }}
                            {...register("title")}
                            onChange={(e) => setValue("title", e.target.value)}
                            isInvalid={!!errors.title?.message}
                          />
                          {errors?.title &&
                            errors?.title?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.title?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Label
                          htmlFor="description"
                          className="mb-0"
                          style={{ color: "#666276" }}
                        >
                          Description
                        </Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            id="description"
                            placeholder="Enter Description"
                            style={{ fontSize: "15px", color: "#666276" }}
                            isInvalid={!!errors.description?.message}
                            onChange={(e) =>
                              setValue("description", e.target.value)
                            }
                            {...register("description")}
                          />
                          {errors?.description &&
                            errors?.description?.message !== undefined && (
                              <Form.Control.Feedback
                                className="d-block "
                                type="invalid"
                              >
                                {errors?.description?.message}
                              </Form.Control.Feedback>
                            )}
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          controlId="isPrimaryCheckbox"
                          className="mt-2"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Set entity as primary"
                            name="isPrimary"
                            value={watch("isPrimary")}
                            onChange={(e) =>
                              setValue("isPrimary", e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                )}
                <Card.Footer className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
                    }
                  >
                    <Button
                      className="me-3 btn-sm"
                      variant="secondary"
                      onClick={() => {
                        setAddNewPaymentDetails(false);
                        reset();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Save the entry</Tooltip>
                    }
                  >
                    <Button
                      className="btn-sm"
                      type="Submit"
                      variant="primary"
                      onClick={handleSubmit(createOrUpdatePaymentDetails)}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
              </Card>
            </Form>
          )} */}
        {!addNewPaymentDetails && (<div
              style={{
                display: "flex",
                justifyContent: "end",
                backgroundColor:"white",
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "999",
              }}
            >
              {paymentDetailsEditable && (
                <Button
                  variant="secondary"
                  className="mx-2"
                  onClick={() => {
                    setPaymentDetailsEditable(false);
                  }}
                >
                  Cancel
                </Button>
              )}
              {/* <Button
                variant="primary"
                onClick={() => {
                  setAddNewPaymentDetails(true);
                }}
              >
                Add Account
              </Button> */}
            </div>)}
        </Card.Body>
        <ToastContainer style={{ top: "60px" }} />
      </Card>
      <Modal
        centered
        show={deletePayment?.isOpen}
        onHide={() => setDeletePayment({ ...deletePayment, isOpen: false })}
      >
        <Modal.Header className="border-0 justify-content-center fw-bold fs-6">
          Alert!
        </Modal.Header>

        <Modal.Body className="text-center fs-6">
          {deletePayment?.msg}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            className="mx-2"
            onClick={() => {
              setDeletePayment({
                ...deletePayment,
                isOpen: false,
                id: "",
              });
            }}
          >
            Cancel
          </Button>
          {!deletePayment?.msg?.includes(
            "Please create another account as the primary one"
          ) && (
            <Button
              size="sm"
              disabled={deletePayment?.isLoader}
              onClick={() => {
                handleDeletePayment();
              }}
            >
              {deletePayment?.isLoader ? "Proceeding..." : "Proceed"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </div>
  );
}
