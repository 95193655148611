import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { CiBoxList } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CgProfile } from "react-icons/cg";
import Button from "react-bootstrap/Button";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { onLogout } from "../../store/Auth/AuthActions";
import '../../styles.css';

const TopNavBar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userInfo);
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const userName = user && `${user.firstname} ${user?.lastname}`;
  const role = user && `${user?.role?.role}`;
  const first = user && `${user.firstname}`;
  const last = user && `${user?.lastname}`;

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  }

  useEffect(() => {

    if (window.innerWidth <= 992) {
      document.body.classList.add("sidenav-toggled");
    }

    // Enable tooltips globally
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });

    // Enable popovers globally
    const popoverTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map((popoverTriggerEl) => {
      return new window.bootstrap.Popover(popoverTriggerEl);
    });

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", (event) => {
        event.preventDefault();
        document.body.classList.toggle("sidenav-toggled");
        localStorage.setItem(
          "sb|sidebar-toggle",
          document.body.classList.contains("sidenav-toggled")
        );
      });
    }

    // Add active state to sidebar nav links
    let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, "$1");

    if (activatedPath) {
      activatedPath = activatedPath[0];
    } else {
      activatedPath = "index.html";
    }

    const targetAnchors = Array.from(
      document.querySelectorAll('[href="' + activatedPath + '"].nav-link')
    );

    targetAnchors.forEach((targetAnchor) => {
      let parentNode = targetAnchor.parentNode;
      while (parentNode !== null && parentNode !== document.documentElement) {
        if (parentNode.classList.contains("collapse")) {
          parentNode.classList.add("show");
          const parentNavLink = document.body.querySelector(
            '[data-bs-target="#' + parentNode.id + '"]'
          );
          parentNavLink.classList.remove("collapsed");
          parentNavLink.classList.add("active");
        }
        parentNode = parentNode.parentNode;
      }
      targetAnchor.classList.add("active");
    });

    // ******
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    // Add event listener when the component mounts
    window.addEventListener('click', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // *******
  }, []);

  function getCurrentDimension() {
    if (window.innerWidth < 968) {
      if (!document.body.classList.contains("sidenav-toggled")) {
        document.body.classList.add("sidenav-toggled");
      }
    } else {
      document.body.classList.remove("sidenav-toggled");
    }
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const logOut = () => {
    dispatch(onLogout());
    navigate("/login", { replace: true });
  };
  const profile = () => {
    navigate("/profile");
  };
  const payment_details = () => {
    navigate("/payment_details");
  }
  useEffect(() => {
    window.addEventListener("resize", getCurrentDimension);
    return () => {
      window.removeEventListener("resize", getCurrentDimension);
    };
  }, []);
  const account_preferences = () => {
    navigate("/account_preferences");
  };

  return (
    <>
      <Nav
        className="position-fixed top-0 bg-white p-1 px-2 w-100 shadow-sm  navbar navbar-expand justify-content-between justify-content-sm-start  shadow-none border-bottom"
        style={{ zIndex: 2 }}
      >
        <div style={{ width: 200, minHeight: 55 }}>
          <Link to={"/"} className="navbar-brand">
            {userInfo?.account_preferences?.attached_logo ? (
              <img
                alt="temp"
                src={`
                    ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                  `}
                style={{ maxHeight: 55 }}
              />
            ) : (
              "{Logo}"
            )}
            {/* <img src="/images/logoiph.png" alt="logo" style={{ height: 55 }} /> */}
          </Link>
        </div>

        <Button
          variant="secondary"
          className="btn btn-sm btn-icon order-1 order-lg-0 me-lg-2 mx-2"
          id="sidebarToggle"
        >
          <FiMenu size={24} />
        </Button>

        <div className=" navbar-nav align-items-center ms-auto d-none d-sm-block" ref={dropdownRef}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="account-tooltip">Account</Tooltip>}
        >
        <div className="d-flex align-items-center justify-content-center ms-2 me-2" onClick={handleDropdown} style={{height:'40px', width:'40px', borderRadius:'100%', backgroundColor:'orange',textAlign:'center',padding:'4px', cursor:'pointer'}}>
            <span className="fs-5 fw-semibold text-white">{first[0]}{last[0]}</span>
          </div>
        </OverlayTrigger>
          {openDropdown && (
        <div className="position-absolute d-flex flex-column justify-content-between border rounded bg-white" style={{top:"60px", right:"30px", width:"220px"}}>
          <div className="mt-3 border-bottom">
            <div className="ms-3 fw-semibold">{userName}</div>
            <div className="mb-2 ms-3 text-capitalize">{role}</div>
          </div>
          <div onClick={()=>{handleDropdown(); profile();}} className="d-flex align-items-center my-3 text-hover" style={{ cursor:'pointer' }}>
            <CgProfile className="ms-3" size={24} /> <div className="ps-2">My Profile</div>
          </div>
          <div onClick={()=>{handleDropdown(); payment_details();}} className="d-flex align-items-center mb-3 text-hover" style={{ cursor:'pointer'}}>
            <RiMoneyDollarCircleLine className="ms-3" size={24}/>
            <div className="ps-2">Payment Details</div>
          </div>
          <div onClick={()=>{handleDropdown(); account_preferences();}} className="d-flex align-items-center mb-4 text-hover" style={{ cursor:'pointer'}}>
            <CiBoxList className="ms-3" size={24}/>
            <div className="ps-2">Account Preferences</div>
          </div>
          <div onClick={()=>{handleDropdown(); logOut();}} className="d-flex align-items-center mt-2 mb-3 text-hover" style={{ cursor:'pointer'}}>
            <div className="d-flex align-items-center justify-content-center ms-3 me-3 rounded w-100 border border-black" style={{height:"35px"}}>
              <div className="">Log Out</div>
            </div>
          </div>
        </div>
      )}
          {/* <Dropdown> */}
            {/* <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 200 }}
              overlay={<Tooltip id="button-tooltip">Account</Tooltip>}
            > */}
              {/* <Dropdown.Toggle
                as="div"
                variant="success"
                style={{ cursor: "pointer" }}
              >
                {userName}
              </Dropdown.Toggle> */}
            {/* </OverlayTrigger> */}

            {/* <Dropdown.Menu className="me-4" style={{ width: "200px", height:"180px" }}> */}
              {/* <Dropdown.Item
                onClick={profile}
                className="d-flex align-items-center"
                style={{ marginBottom: "8px", marginTop:"4px" }}
              >
                <CgProfile size={24} /> <div className="ps-2">My Profile</div>
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                onClick={account_preferences}
                className="d-flex align-items-center"
                style={{ marginBottom: "8px" }}
              >
                <CiBoxList size={24} />
                <div className="ps-2">Account Preferences</div>
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                onClick={logOut}
                className="d-flex align-items-center"
              > */}
                {/* <div className="d-flex align-items-center justify-content-center text-white ms-2 me-2 rounded w-100" style={{backgroundColor:"#FD6241", height:"30px"}}>
                <RiLogoutCircleLine size={18} />
                <div className="">Log Out</div>
                </div> */}
              {/* </Dropdown.Item> */}
            {/* </Dropdown.Menu> */}
          {/* </Dropdown> */}
        </div>

        
      </Nav>
    </>
  );
};

export default TopNavBar;
