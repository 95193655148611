import React, { useEffect, useState, useRef, useCallback } from "react";
import "../App.css";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-buttons/js/buttons.print.mjs";
import DataTable from "datatables.net-bs5";
import { dataTableConfig } from "../Utils/DataTableConfig";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

var checkbox_data_array = [];

const DataTables = (props) => {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    date: yup.string().required("Date is required"),
    summary: yup.string().required("Description is required"),
    voucher_number: yup.string().nullable(),
    transaction_type: yup.string().required("Transaction is required"),
    amount: yup.string().required("Amount is required"),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors },
    trigger,
  } = useForm({
    // Define validation schema using yup
    defaultValues: {
      transaction_type: "0",
      voucher_number: null,
      bill_image: null,
      date: moment().format("YYYY-MM-DD"),
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    url,
    columns,
    defaultSortedColumnIndex,
    requestMethod ,
    dispatchCallType,
    setSelectedItems = false,
    additionalFilter,
    handleImportModal,
    handleCustomExport,
    noOrder = false,
    setAddEntry = false,
    addEntry = false,
    onSubmit,
    isDeleting,
  } = props;

  //console.log("The column is -->", columns);
  const [isSubmit, setIsSubmit] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { theme,userInfo } = useSelector((state) => state);


  const configureDataTable = dataTableConfig(
    url,
    requestMethod || "GET",
    columns,
    userInfo,
    defaultSortedColumnIndex || 0,
    "",
    dispatch,
    dispatchCallType,
    additionalFilter,
    handleImportModal,
    handleCustomExport,
    noOrder ? false : true,
    addEntry
  );

  const handleCheckBox = (component, isAll = false, selectedItemIds) => {
    if (isAll) {
      const checkBoxes = document.querySelectorAll(".checkBox");
      const isChecked = component.checked;
      //console.log("All check box clicked");

      checkBoxes.forEach((checkbox) => {
        checkbox.checked = isChecked;
      });
    }

    const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
    const multipleDeleteBtn = document.getElementById("multipleDeleteBtn");
    const duplicateInvoice = document.getElementById("duplicateInvoice");
    const duplicateVoucher = document.getElementById("duplicateVoucher");

    const checkAll = document.querySelector(".checkAll");
    const checkBoxes = document.querySelectorAll(".checkBox");
    if (duplicateInvoice) {
      if (checkedCheckBoxes.length === 1) {
        duplicateInvoice.style.display = "block";
      } else {
        duplicateInvoice.style.display = "none";
      }
    }

    if (duplicateVoucher) {
      if (checkedCheckBoxes.length === 1) {
        duplicateVoucher.style.display = "block";
      } else {
        duplicateVoucher.style.display = "none";
      }
    }

    if (multipleDeleteBtn) {
      if (checkedCheckBoxes.length > 0) {
        multipleDeleteBtn.style.display = "block";
      } else {
        multipleDeleteBtn.style.display = "none";
      }
    }

    if (checkedCheckBoxes.length === checkBoxes.length) {
      checkAll.checked = true;
    } else {
      checkAll.checked = false;
    }
    checkbox_data_array = Array.from(checkedCheckBoxes).map((checkbox) => {
      return checkbox?.value;
    });
    if (setSelectedItems !== false) {
      setSelectedItems(checkbox_data_array);
    }

    return checkbox_data_array;
  };

  const handleDataValueClick = (event) => {
    const target =
      event.target.getAttribute("data-action") === null
        ? event?.target?.parentElement?.closest("[data-action]")
        : event.target;
    const dataAction = target.getAttribute("data-action");
    if (dataAction)
      switch (dataAction) {
        case "view":
          navigate(`view/${target.getAttribute("data-id")}`);
          break;

        case "edit":
          navigate(`edit/${target.getAttribute("data-id")}`);
          break;

        case "checkBox":
          handleCheckBox(target);
          break;

        case "checkAll":
          handleCheckBox(target, true);
          break;

        default:
          const functionNameToCall = props[dataAction];
          if (typeof functionNameToCall === "function") {
            functionNameToCall(target.getAttribute("data-id"));
          }
          break;
      }
  };

  const refreshTable = theme.refreshDataTable;

  let elementsWithDataValue = document.querySelectorAll("button[data-action]");

  useEffect(() => {
    let elementsWithDataValue = document.querySelectorAll(
      "button[data-action]"
    );
    let elementsWithInputDataValue =
      document.querySelectorAll("input[data-action]");
    elementsWithDataValue.forEach((element) => {
      element.addEventListener("click", handleDataValueClick);
    });
    elementsWithInputDataValue.forEach((element) => {
      element.addEventListener("click", handleDataValueClick);
    });

    return () => {
      elementsWithDataValue.forEach((element) => {
        element.removeEventListener("click", handleDataValueClick);
      });
      elementsWithInputDataValue.forEach((element) => {
        element.removeEventListener("click", handleDataValueClick);
      });
    };
  }, [elementsWithDataValue]);

  useEffect(() => {
    const dataTable = new DataTable(tableRef.current, configureDataTable);
    addCashbookEntry();
    return () => {
      if (dataTable) dataTable.destroy();
    };
  }, [refreshTable, additionalFilter, addEntry, errors]);

  const addCashbookEntry = useCallback(() => {
    const customInputWrapper = document.querySelector(
      ".custom-input-field-wrapper"
    );

    if (customInputWrapper && addEntry) {
      const inputFieldContainer = document.createElement("div");
      inputFieldContainer.className = "input-field-container d-flex";

      const fieldsToAdd = [
        {
          label: "Date",
          name: "date",
          value: moment().format("YYYY-MM-DD"),
        },
        {
          label: "Description",
          name: "summary",
          value: "",
        },
        {
          label: "Voucher ID",
          name: "voucher_number",
          value: null,
        },
        {
          label: "Transaction type",
          name: "transaction_type",
          value: "0",
        },
        {
          label: "Amount",
          name: "amount",
          value: "",
        },
      ];

      fieldsToAdd.forEach((field) => {
        const fieldContainer = document.createElement("div");
        fieldContainer.className = "field-container"; // Add a class for styling
        fieldContainer.style.marginRight = "16px";

        if (field.name === "transaction_type") {
          const dropdown = document.createElement("select");
          dropdown.className = "form-select mr-2";
          dropdown.name = field.name;
          dropdown.style.marginRight = "16px";

          const optionCredit = document.createElement("option");
          optionCredit.value = "1";
          optionCredit.textContent = "Credit";

          const optionDebit = document.createElement("option");
          optionDebit.value = "0";
          optionDebit.textContent = "Debit";
          optionDebit.selected = true;

          dropdown.appendChild(optionDebit);
          dropdown.appendChild(optionCredit);

          dropdown.addEventListener("change", (event) => {
            const value = event.target.value;
            setValue(field.name, value);
          });
          fieldContainer.appendChild(dropdown);
        } else {
          const inputField = document.createElement("input");
          if(field.name === "amount"){
            inputField.type = "number";
          }
          else{
            inputField.type = "text";
          }

          if (field.name === "date") {
            window.flatpickr(inputField, {
              enableTime: false,
              dateFormat: "Y-m-d",
              maxDate: new Date(),
              defaultDate: watch(field.name) || field.value,
            });
          } else {
            inputField.value = watch(field.name) || field.value; // Set default value for other fields
          }

          inputField.placeholder = field.label;
          inputField.className = "form-control mr-2";
          inputField.name = field.name;
          // console.log("The field name is -->", field.name);

          register(field.name);
          inputField.addEventListener("input", (event) => {
            const value = event.target.value;
            setValue(field.name, value);
          });

          fieldContainer.appendChild(inputField);
        }

        if (errors[field.name]) {
          const errorMessage = document.createElement("p");
          errorMessage.textContent = errors[field.name].message;
          errorMessage.className = "text-danger ml-2";
          errorMessage.style.marginLeft = "8px";
          fieldContainer.appendChild(errorMessage);
        }

        inputFieldContainer.appendChild(fieldContainer);
      });

      const cancelButton = document.createElement("button");
      cancelButton.textContent = "Cancel";
      cancelButton.className = "px-2 btn btn-secondary btn-sm";
      cancelButton.style.height = "fit-content";
      cancelButton.style.padding = "9px";
      cancelButton.addEventListener("click", () => {
        if (addEntry) {
          setAddEntry(false);
          reset();
        }
      });

      const submitButton = document.createElement("button");
      submitButton.textContent = "Submit";
      submitButton.className = "px-4 ml-2 btn btn-success btn-sm";
      submitButton.style.marginRight = "10px";
      submitButton.style.height = "fit-content";
      submitButton.style.padding = "9px";

      if (isSubmit) {
        submitButton.disabled = true;
      } else {
        submitButton.disabled = false;
      }

      submitButton.addEventListener("click", async (e) => {
        const isValid = await trigger(); // Trigger validation for all fields
        if (isValid) {
          setIsSubmit(true); // Set submit state before processing
          handleSubmit((data) => {
            onSubmit(data); // Pass form data to the onSubmit function
          })(); // Manually call handleSubmit if valid
          setTimeout(() => {
            setAddEntry(false);
            reset();
            setIsSubmit(false);
          }, 3000);
        } else {
          setIsSubmit(false);
        }
      });

      inputFieldContainer.appendChild(submitButton);
      inputFieldContainer.appendChild(cancelButton);

      customInputWrapper.appendChild(inputFieldContainer);
    }
  }, [errors, addEntry]);

  return (
    <>
      <Table
        style={{ width: "100%" }}
        className="table-striped table-bordered border-start"
        ref={tableRef}
      />
      <div className="custom-input-field-wrapper"></div>
    </>
  );
};

export default DataTables;
